import React from 'react'

import { useModal, Modal } from '@gousto-internal/citrus-react'
import actions from 'actions'
import { useDispatch, useSelector } from 'react-redux'
import { browserHistory } from 'react-router'

import { BasketChangesContent } from '@features/basket'

import { actionTypes } from 'actions/actionTypes'
import { useGetYourBoxData } from 'routes/Checkout/Components/BoxDetailsHighlight/YourBox'
import { getBasketRecipesIDs } from 'routes/Checkout/Components/CheckoutBoxTangibility/selectors'
import { useBasket, useNumRecipesOverBasketLimit } from 'routes/Menu/domains/basket'
import { useGetUnavailableRecipeIds } from 'routes/Menu/hooks/useGetUnavailableRecipeIds'
import { createGetActionTypeIsPending } from 'selectors/status'

import { Recipe } from './types'

export const BasketChangesModal = () => {
  const { closeCurrentModal } = useModal()
  const dispatch = useDispatch()
  const basketRecipesIds = useSelector(getBasketRecipesIDs)
  const numRecipesOverLimit = useNumRecipesOverBasketLimit()
  const { removeRecipe } = useBasket()
  const { maxRecipesNum, numPortions, menuRecipesStore } = useGetYourBoxData()
  const unavailableRecipeIds = useGetUnavailableRecipeIds()

  const isLoading = useSelector(createGetActionTypeIsPending(actionTypes.MENU_FETCH_DATA))
  const isOverRecipeLimit = numRecipesOverLimit > 0

  const selectedRecipes = basketRecipesIds.map((recipeId: number) => {
    const recipe = menuRecipesStore.get(recipeId.toString())?.toJS() as Recipe
    const defaultImageCrops = recipe?.media?.images?.[0].urls || []
    const moodImageCrops = recipe?.media?.images.find((image) => image.type === 'mood-image')?.urls
    const coreRecipeId = recipe?.coreRecipeId || ''

    return {
      moodImage: { crops: moodImageCrops || defaultImageCrops },
      name: recipe?.title,
      uuid: recipe?.uuid,
      coreRecipeId,
      isOutOfStock: unavailableRecipeIds.includes(coreRecipeId),
    }
  })

  const isAnyRecipeOutOfStock = selectedRecipes.some((recipe) => recipe.isOutOfStock)
  const isModalOpen = !isLoading && (isOverRecipeLimit || isAnyRecipeOutOfStock)

  const goBack = () => {
    dispatch(actions.basketEditModalClosed(selectedRecipes, maxRecipesNum, numPortions))
    closeCurrentModal()
    browserHistory.goBack()
  }

  const submitRemovedRecipes = (recipeUUIDsToRemove: string[]) => {
    dispatch(
      actions.basketEditChangesConfirmed(
        selectedRecipes,
        recipeUUIDsToRemove,
        maxRecipesNum,
        numPortions,
      ),
    )
    closeCurrentModal()
    recipeUUIDsToRemove.forEach((uuid) => {
      const coreRecipeId = selectedRecipes.find((recipe) => recipe.uuid === uuid)?.coreRecipeId

      if (coreRecipeId) {
        removeRecipe(coreRecipeId, 'basket_changes_modal')
      }
    })
  }

  const handleAfterOpen = () => {
    dispatch(actions.basketEditModalOpen(selectedRecipes, maxRecipesNum, numPortions))
  }

  return (
    <Modal
      shouldCloseOnOverlayClick={false}
      isOpen={isModalOpen}
      name="basket-changes"
      onAfterOpen={handleAfterOpen}
      preventScroll
    >
      <BasketChangesContent
        maxNumberOfRecipes={maxRecipesNum}
        numberOfPortionsPerBox={numPortions}
        recipes={selectedRecipes}
        submitRemovedRecipes={submitRemovedRecipes}
        goBack={goBack}
      />
    </Modal>
  )
}
