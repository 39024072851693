import React, { ComponentType, useCallback, useEffect, useRef, useState } from 'react'

import { datadogLogs } from '@datadog/browser-logs'
import * as braintree from 'braintree-web'
import { useDispatch, useSelector } from 'react-redux'

import { trackUTMAndPromoCode } from 'actions/tracking'
import { Payment } from 'routes/Checkout/Steps/Payment'
import {
  clearPayPalErrors,
  fetchPayPalClientToken,
  firePayPalError,
  openLoginModal,
  setPayPalDeviceData,
  trackCheckoutButtonPressed,
} from 'routes/Checkout/checkoutActions'
import { CHECKOUT_STEPS, CheckoutStepIds } from 'routes/Checkout/checkoutConfig'
import { getPayPalClientToken } from 'routes/Checkout/checkoutPaymentSelectors'
import { CommonCheckoutStepProps } from 'routes/Checkout/models/CommonCheckoutStepProps'
import { useSubmitOrder } from 'routes/Checkout/useSubmitOrder'

type CheckoutStepContainerProps = {
  /**
   * Step to display.
   */
  currentStepId?: CheckoutStepIds
  /**
   * Callback to proceed to next step.
   */
  onStepChange: (nextStepId: CheckoutStepIds) => void
}

/**
 * Container for single Checkout step.
 * While displaying step, it also displays:
 * - Pre-rendered but hidden Payment step,
 * - Box Summary; on big screens to the side of step; on small screens is hidden under drawer.
 */
export const CheckoutStepContainer = ({
  currentStepId,
  onStepChange,
}: CheckoutStepContainerProps) => {
  const dispatch = useDispatch()
  const submitOrder = useSubmitOrder()
  const braintreeToken = useSelector(getPayPalClientToken)
  const [braintreeClientInstance, setBraintreeClientInstance] = useState<braintree.Client | null>(
    null,
  )
  const [isPayPalInitialized, setIsPayPalInitialized] = useState(false)
  const googlePayInstanceRef = useRef<braintree.GooglePayment | null>(null)
  const [threeDSecureInstance, setThreeDSecureInstance] = useState<braintree.ThreeDSecure | null>(
    null,
  )

  const initBraintree = useCallback(async () => {
    const createBraintreeClient = async () => {
      const clientInstance = await braintree.client.create({ authorization: braintreeToken })
      setBraintreeClientInstance(clientInstance)
    }

    dispatch(clearPayPalErrors())
    setIsPayPalInitialized(false)
    try {
      await createBraintreeClient()
    } catch (error: any) {
      datadogLogs.logger.error('Braintree initialization failed', error)
      dispatch(firePayPalError(error))
    }
  }, [dispatch, braintreeToken])

  const initThreeDSecure = useCallback(async () => {
    if (!braintreeClientInstance) return
    try {
      const threeDS = await braintree.threeDSecure.create({
        client: braintreeClientInstance,
        version: 2,
      })
      setThreeDSecureInstance(threeDS)
      datadogLogs.logger.info('threeDSecureInstance created')
    } catch (error) {
      datadogLogs.logger.error(`threeDSecureInstance failed with ${JSON.stringify(error)}`)
    }
  }, [braintreeClientInstance])

  const initDataCollector = useCallback(async () => {
    const fetchDeviceData = async () => {
      if (!braintreeClientInstance) return
      const dataCollectorInstance = await braintree.dataCollector.create({
        client: braintreeClientInstance,
        paypal: true,
      })
      // we need to set it in redux because eventually these data are sent to
      // payments API, in checkoutSignupPayment.ts
      dispatch(setPayPalDeviceData(dataCollectorInstance.deviceData))
    }

    try {
      await fetchDeviceData()
    } catch (error: any) {
      datadogLogs.logger.error('PayPal data collection failed', error)
      dispatch(firePayPalError(error))
    }
  }, [dispatch, braintreeClientInstance])

  const initGooglePay = useCallback(async () => {
    if (googlePayInstanceRef.current) {
      return
    }
    const createGooglePayInstance = async () => {
      if (!braintreeClientInstance) return
      const google = await braintree.googlePayment.create({
        client: braintreeClientInstance,
        googlePayVersion: 2,
      })
      googlePayInstanceRef.current = google
    }

    try {
      await createGooglePayInstance()
    } catch (error: any) {
      datadogLogs.logger.error('initGooglePay', error)
    }
  }, [braintreeClientInstance])

  // to initalise braintree client, needed before paypal client can be initalised

  useEffect(() => {
    if (!braintreeToken) {
      dispatch(fetchPayPalClientToken())
    }
  }, [braintreeToken, dispatch])

  useEffect(() => {
    if (braintreeToken) {
      initBraintree()
    }
  }, [braintreeToken, initBraintree])

  useEffect(() => {
    if (braintreeClientInstance) {
      initThreeDSecure()
    }
  }, [braintreeClientInstance, initThreeDSecure])

  // to initalise dataCollector, needed before paypal can be initalised
  useEffect(() => {
    if (braintreeClientInstance) {
      initDataCollector()
    }
  }, [braintreeClientInstance, initDataCollector])

  useEffect(() => {
    if (braintreeClientInstance) {
      initGooglePay()
    }
  }, [braintreeClientInstance, initGooglePay])

  useEffect(
    () => () => {
      if (threeDSecureInstance) {
        threeDSecureInstance.teardown()
        setThreeDSecureInstance(null)
      }
    },

    [threeDSecureInstance, setThreeDSecureInstance],
  )

  if (!currentStepId) return <div data-testid="noStepSuppliedPlaceholder" />
  const isCurrentStepIsPaymentStep = currentStepId === CheckoutStepIds.PAYMENT
  const stepInfo = CHECKOUT_STEPS.find((checkoutStep) => checkoutStep.id === currentStepId)
  if (!stepInfo) return <div data-testid="invalidStepPlaceholder" />
  const isCurrentStepIsLastStep = stepInfo.isLastStep
  const StepComponent = stepInfo.component as ComponentType<CommonCheckoutStepProps>

  return (
    <>
      {!isCurrentStepIsPaymentStep && (
        <StepComponent
          isLastStep={isCurrentStepIsLastStep}
          googlePayInstance={googlePayInstanceRef.current}
          onStepChange={onStepChange}
          trackUTMAndPromoCode={(...args: any[]) => dispatch(trackUTMAndPromoCode(...args))}
          trackClick={(...args: any[]) => dispatch(trackCheckoutButtonPressed(...args))}
          submitOrder={submitOrder}
          onLoginClick={openLoginModal}
          braintreeClientInstance={braintreeClientInstance}
          setBraintreeClientInstance={setBraintreeClientInstance}
          threeDSecureInstance={threeDSecureInstance}
        />
      )}
      <Payment
        prerender={!isCurrentStepIsPaymentStep}
        onLoginClick={openLoginModal}
        submitOrder={submitOrder}
        isPayPalInitialized={isPayPalInitialized}
        setIsPayPalInitialized={setIsPayPalInitialized}
        braintreeClientInstance={braintreeClientInstance}
        setBraintreeClientInstance={setBraintreeClientInstance}
        threeDSecureInstance={threeDSecureInstance}
      />
    </>
  )
}
