/**
 * API session will time out after 25 minutes, in milliseconds.
 */
export const timeToTimeoutSessionControl = 25 * 60 * 1000
/**
 * API session will time out after 15 minutes, in milliseconds.
 * This is for the Urgent Checkout Timer experiment (https://gousto.atlassian.net/browse/BEET-465).
 */
export const timeToTimeoutSessionTreatment = 15 * 60 * 1000
/**
 * Modal that warns user about short window to check out should show 3 minutes before time out, in milliseconds.
 */
export const timeLeftToDisplayModal = 3 * 60 * 1000
/**
 * Clock that shows amount of time left should highlight when there's less than a minute left before session check out, in milliseconds.
 */
export const timeLeftToHighlightClock = 60 * 1000
