import React, { useState } from 'react'

import {
  AlignItems,
  Box,
  Color,
  Display,
  JustifyContent,
  Space,
  Text,
  colors,
  Icon,
  withCitrusTheme,
} from '@gousto-internal/citrus-react'
import { useDispatch, useSelector } from 'react-redux'

import { menuPreferencesVisibilityChange } from 'actions/menu'
import { getMenuPreferencesShow } from 'selectors/root'
import { onSpaceOrEnter } from 'utils/accessibility'

// TODO: This is a temp hack. We need to update the icon on Citrus and then import it from there.
const ButtonIcon = withCitrusTheme(Icon, () => ({
  marginBottom: '-2px',
}))

type MenuPreferencesProps = {
  variant: 'desktop' | 'mobile'
}

export const MenuPreferencesButton = ({ variant }: MenuPreferencesProps) => {
  const dispatch = useDispatch()
  const showMenuPreferences = useSelector(getMenuPreferencesShow)
  const toggleSidebar = () => dispatch(menuPreferencesVisibilityChange(!showMenuPreferences))
  const [isButtonHovered, setIsButtonHovered] = useState(false)

  const color = isButtonHovered ? Color.Secondary_400 : Color.ColdGrey_900

  return (
    <Box display={Display.Flex} justifyContent={JustifyContent.FlexEnd}>
      <Box
        data-testid={`menu-preferences-button-${variant}`}
        role="button"
        display={Display.Flex}
        alignItems={AlignItems.Center}
        color={color}
        onClick={toggleSidebar}
        onKeyDown={onSpaceOrEnter(toggleSidebar)}
        tabIndex={0}
        onFocus={() => setIsButtonHovered(true)}
        onBlur={() => setIsButtonHovered(false)}
        onMouseEnter={() => setIsButtonHovered(true)}
        onMouseLeave={() => setIsButtonHovered(false)}
        style={{ cursor: 'pointer', marginLeft: '1rem' }}
      >
        {variant === 'desktop' && (
          <>
            <Text size={3}>Preferences</Text>
            <Space size={2} direction="horizontal" />
          </>
        )}
        <ButtonIcon name="preferences" color={colors[color]} />
      </Box>
    </Box>
  )
}
