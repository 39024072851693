import React from 'react'

import { Space } from '@gousto-internal/citrus-react'
import PropTypes from 'prop-types'

import { capitalizeFirstLetter } from 'utils/text'

import { DiscountTagAsteriskNote } from './DiscountTagAsteriskNote/DiscountTagAsteriskNote'
import { FreeDeliveryCard } from './FreeDeliveryCard/FreeDeliveryCard'

import css from './OrderSchedule.css'
import { WhatHappenNext } from './WhatHappenNext/WhatHappenNext'

const propTypes = {
  deliveryDate: PropTypes.string.isRequired,
  nameFirst: PropTypes.string,
  whenCutoff: PropTypes.string.isRequired,
  interval: PropTypes.string,
  numRecipes: PropTypes.number.isRequired,
  numPortions: PropTypes.number.isRequired,
  discountSofteningFreeDeliveryValue: PropTypes.string,
  showOrderSummary: PropTypes.bool,
}

const defaultProps = {
  nameFirst: '',
  interval: 'weekly',
  discountSofteningFreeDeliveryValue: 'c',
  showOrderSummary: true,
}

const isValidDate = (date) => (date && date.length) || typeof date === 'object'

const OrderSchedule = ({
  deliveryDate,
  nameFirst,
  whenCutoff,
  interval,
  numRecipes,
  numPortions,
  discountSofteningFreeDeliveryValue,
  showOrderSummary,
}) => {
  const isValidDeliveryDate = isValidDate(deliveryDate)
  const isValidWhenCutoff = isValidDate(deliveryDate)

  const isFiveForFourOrder = numRecipes === 5 && numPortions === 4

  const isFreeDeliveryEnabled = ['t2', 't3'].includes(discountSofteningFreeDeliveryValue)
  const isNewDiscountUIEnabled = ['t1', 't3'].includes(discountSofteningFreeDeliveryValue)

  return (
    <div data-testing="orderScheduleContainer" className={css.container}>
      <div className={css.header}>
        <h2>{`Congrats on choosing your first recipes, ${capitalizeFirstLetter(nameFirst)}!`}</h2>
        {isFreeDeliveryEnabled ? (
          <>
            <FreeDeliveryCard />
            <Space size={[3, 2]} />
          </>
        ) : (
          <p className={css.headerSpacing}>
            We&apos;ll send you an email with tracking information when your first order is on its
            way.
          </p>
        )}
        <h2>What happens next?</h2>
      </div>
      <WhatHappenNext
        deliveryDate={deliveryDate}
        whenCutoff={whenCutoff}
        interval={interval}
        discountSofteningFreeDeliveryValue={discountSofteningFreeDeliveryValue}
        isFiveForFourOrder={isFiveForFourOrder}
        isNewDiscountUIEnabled={isNewDiscountUIEnabled}
        isValidWhenCutoff={isValidWhenCutoff}
        isValidDeliveryDate={isValidDeliveryDate}
        showOrderSummary={showOrderSummary}
      />
      {isNewDiscountUIEnabled && <DiscountTagAsteriskNote />}
    </div>
  )
}

OrderSchedule.propTypes = propTypes
OrderSchedule.defaultProps = defaultProps

export { OrderSchedule }
