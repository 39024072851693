import React from 'react'

import { Box } from '@gousto-internal/citrus-react'
import { InfoWarning } from './InfoWarning'
import { InfoSuccess } from './InfoSuccess'

type InfoProps = {
  hasError: boolean
  isAnyRecipeOutOfStock: boolean
  numberOfPortionsPerBox: number
  maxNumberOfRecipes: number
  isOverLimitBy: number
}

export const Info = ({
  hasError,
  isAnyRecipeOutOfStock,
  numberOfPortionsPerBox,
  maxNumberOfRecipes,
  isOverLimitBy,
}: InfoProps) => {
  const isOverLimit = isOverLimitBy > 0

  const errorMessages = []

  if (isAnyRecipeOutOfStock) {
    errorMessages.push('Greyed out recipes are not available and will be removed.')
  }
  if (isOverLimit) {
    errorMessages.push(
      `Only ${maxNumberOfRecipes} recipes fit in a ${numberOfPortionsPerBox}-person box. Remove ${isOverLimitBy} recipe${
        isOverLimitBy > 1 ? 's' : ''
      } to check out.`,
    )
  }
  return <Box>{hasError ? <InfoWarning messages={errorMessages} /> : <InfoSuccess />}</Box>
}
