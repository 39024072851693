import { Crop } from '../types'

export const getDefaultImage = (srcs: Crop[]) => {
  const sortedSrcs = srcs.slice().sort((a, b) => b.width - a.width)
  const midpointInArray = Math.floor(sortedSrcs.length / 2)

  return sortedSrcs[midpointInArray]?.src || ''
}

export const getSrcSet = (srcs: Crop[]): string => {
  return srcs.map(({ src, width }) => `${src} ${width}w`).join()
}
