import { endpoint } from '@library/endpoint'

import routes from 'config/routes'
import { fetch } from 'utils/fetch'

const customersApi = {
  customerSignup,
}

export function customerSignup(accessToken, reqData, signupEndpointVerion = 2) {
  const TIMEOUT = 50000

  return fetch(
    accessToken,
    `${endpoint('customers', signupEndpointVerion)}${routes.customers.signup}`,
    reqData,
    'POST',
    'default',
    {},
    TIMEOUT,
  )
}

export const generateReference = () =>
  fetch(null, `${endpoint('core')}${routes.core.reference}`, {}, 'POST')

export default customersApi
