import { useSelector } from 'react-redux'

import { actionTypes } from 'actions/actionTypes'
import { usePricing } from 'routes/Menu/domains/pricing'
import { createGetActionTypeIsPending, createGetErrorForActionType } from 'selectors/status'

export const useCheckoutButtonIsPending = () => {
  const orderSaveError = useSelector(createGetErrorForActionType(actionTypes.ORDER_SAVE))
  const { isPending: pricingPending } = usePricing()
  const checkoutPending = useSelector(createGetActionTypeIsPending(actionTypes.BASKET_CHECKOUT))
  const basketPreviewOrderChangePending = useSelector(
    createGetActionTypeIsPending(actionTypes.BASKET_PREVIEW_ORDER_CHANGE),
  )
  const orderSavePending = useSelector(createGetActionTypeIsPending(actionTypes.ORDER_SAVE))
  const loadingOrderPending = useSelector(createGetActionTypeIsPending(actionTypes.LOADING_ORDER))
  const menuFetchData = useSelector(createGetActionTypeIsPending(actionTypes.MENU_FETCH_DATA))

  const isPending =
    (checkoutPending ||
      pricingPending ||
      basketPreviewOrderChangePending ||
      orderSavePending ||
      loadingOrderPending ||
      menuFetchData) &&
    orderSaveError === null

  return isPending
}
