import React, { useCallback, useState } from 'react'

import {
  Icon,
  IconNames,
  Box,
  Button,
  Image,
  Heading1,
  Space,
  UnorderedList,
  ListItem,
  withCitrusTheme,
  Text,
  FlexDirection,
  JustifyContent,
  ButtonColorVariant,
  Modal,
} from '@gousto-internal/citrus-react'

import AppDownloadMobileImage from '../assets/app-download-prompt-mobile.svg'
import AppDownloadTabletImage from '../assets/app-download-prompt-tablet.svg'
import { useWelcomeTracking } from '../hooks/useWelcomeTracking'

type ListType = {
  text: string
  icon: IconNames
}[]

const bulletPointList: ListType = [
  {
    text: 'Access updates wherever you are',
    icon: 'frequency',
  },
  {
    text: 'Track and manage orders easily',
    icon: 'delivery',
  },
  {
    text: 'Never miss an order cut-off',
    icon: 'calendar',
  },
]

type PropsType = {
  device: string
}

type DeviceImagesType = {
  [key: string]: DeviceImageProperties
}

type DeviceImageProperties = {
  alt: string
  src: string
}

const StyledUnorderedList = withCitrusTheme(UnorderedList, () => ({
  paddingInlineStart: '0px',
  paddingTop: '1.25rem',
  paddingBottom: '1.25rem',
}))

const AppDownloadPrompt = (props: PropsType) => {
  const [isDismissed, setIsDismissed] = useState(false)
  const { trackAppDownloadCTAIsClicked, trackAppDownloadDismissCTAIsClicked } = useWelcomeTracking()
  const { device } = props
  const deviceImages: DeviceImagesType = {
    mobile: { alt: 'app download mobile', src: AppDownloadMobileImage },
    tablet: { alt: 'app download tablet', src: AppDownloadTabletImage },
  }

  const handleCTAClick = useCallback(() => {
    trackAppDownloadCTAIsClicked()
    window.location.assign('/apps')
  }, [trackAppDownloadCTAIsClicked])

  const handleDismissClick = useCallback(() => {
    trackAppDownloadDismissCTAIsClicked()
    setIsDismissed(true)
  }, [trackAppDownloadDismissCTAIsClicked])

  return (
    <Modal name="app-download-prompt-modal" isOpen={!isDismissed}>
      <Box data-testid="app-promo-overlay">
        <Image alt={deviceImages[device].alt} src={deviceImages[device].src} />{' '}
        <Box paddingV={4} paddingH={4}>
          <Heading1 size={9}>Meal planning is better in the Gousto app</Heading1>
          <StyledUnorderedList>
            {bulletPointList.map((item) => (
              <Box key={item.text.replace(' ', '-')}>
                <ListItem>
                  <Icon name={item.icon} />
                  <Space size={3} direction="horizontal" />
                  <Text size={4}>{item.text}</Text>
                </ListItem>
                <Space size={3} />
              </Box>
            ))}
          </StyledUnorderedList>
          <Box
            display="flex"
            flexDirection={FlexDirection.Column}
            justifyContent={JustifyContent.SpaceBetween}
          >
            <Button onClick={handleCTAClick}>Download the app</Button>
            <Space size={2} />
            <Button colorVariant={ButtonColorVariant.Tertiary} onClick={handleDismissClick}>
              Dismiss
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export { AppDownloadPrompt }
