import { useSelector } from 'react-redux'

import { getBasketRecipes } from 'selectors/basket'
import { getRecipes } from 'selectors/root'

import { useBasket } from '../domains/basket'
import { getUnavailableRecipeIds } from '../selectors/basket'

export const useGetUnavailableRecipeIds = () => {
  const { numPortions } = useBasket()
  const unavailableRecipeIds = useSelector(getUnavailableRecipeIds).keySeq()
  const recipes = useSelector(getRecipes)
  const basketRecipes = useSelector(getBasketRecipes)

  if (!recipes.size || !basketRecipes.size) {
    return []
  }

  return basketRecipes
    .keySeq()
    .filter((recipeId: string) => {
      const recipe = recipes.get(recipeId)

      return (
        unavailableRecipeIds.includes(recipeId) ||
        !recipe?.get('portionSizesOffered')?.includes(numPortions)
      )
    })
    .toArray() as string[]
}
