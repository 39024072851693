import React from 'react'

import { Box, Image } from '@gousto-internal/citrus-react'

import onePlate from './assets/1-plate.jpg'
import twoPlates from './assets/2-plates.jpg'
import threePlates from './assets/3-plates.jpg'
import fourPlates from './assets/4-plates.jpg'
import fivePlates from './assets/5-plates.jpg'

type PortionSizeSelectorImageProps = {
  portionSize: number
}

const imageMap: { [key: number]: string } = {
  1: onePlate,
  2: twoPlates,
  3: threePlates,
  4: fourPlates,
  5: fivePlates,
}

export const PortionSizeSelectorImage = ({ portionSize }: PortionSizeSelectorImageProps) => {
  const getImageSrc = () => imageMap[portionSize]

  return (
    <Box data-testid="portionSizeSelectorImage" paddingRight={6}>
      <Image src={getImageSrc()} alt={`${portionSize} person box`} />
    </Box>
  )
}
