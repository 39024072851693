import { useSelector } from 'react-redux'

import { actionTypes } from 'actions/actionTypes'
import config from 'config/basket'
import { useBasket, useNumRecipesOverBasketLimit } from 'routes/Menu/domains/basket'
import { getBasketSlotId } from 'selectors/basket'
import { createGetErrorForActionType } from 'selectors/status'

import { useCheckoutButtonIsPending } from './useCheckoutButtonIsPending'

export const useCheckoutButton = () => {
  const isCheckoutButtonPending = useCheckoutButtonIsPending()
  const { recipeCount } = useBasket()
  const orderSaveError = useSelector(createGetErrorForActionType(actionTypes.ORDER_SAVE))
  const numRecipesOverBasketLimit = useNumRecipesOverBasketLimit()
  const isNumRecipesOverBasketLimit = !!numRecipesOverBasketLimit
  const hasSlotId = useSelector(getBasketSlotId)

  const isCheckoutButtonDisabled =
    recipeCount < config.minRecipesNum ||
    orderSaveError !== null ||
    isNumRecipesOverBasketLimit ||
    !hasSlotId

  return { isCheckoutButtonDisabled, isCheckoutButtonPending }
}
