import React, { useCallback, useEffect } from 'react'

import { datadogLogs } from '@datadog/browser-logs'
import {
  AlignItems,
  Body3,
  Box,
  Button,
  Color,
  Display,
  FlexDirection,
  FontWeight,
  Heading4,
  Heading5,
  Join,
  JustifyContent,
  Space,
  Text,
} from '@gousto-internal/citrus-react'
import Loading from 'Loading'
import moment from 'moment'
import { browserHistory } from 'react-router'

import { WhatHappenNext } from '../OrderSchedule/WhatHappenNext/WhatHappenNext'
import { useWelcomeTracking } from '../hooks/useWelcomeTracking'
import { Carousel } from './Carousel/Carousel'
import { RecipeImageTiles } from './RecipeImageTiles'

export type Order = {
  id: string
  deliveryDate: string
  shouldCutoffAt: string
  number: string
  box: {
    numPortions: string
    numRecipes: string
  }
  prices: {
    isDeliveryFree: boolean
    percentageOff: string
  }
}

type Props = {
  orders: Record<string, Order>
  userLoadOrders: (forceRefresh?: boolean) => void
  discountSofteningFreeDeliveryValue: string
}

const getFormattedDeliveryDate = (deliveryDate: string) =>
  moment(deliveryDate, 'YYYY-MM-DD').format('dddd, MMMM D')

let ORDERS_FETCH_CURRENT_ATTEMPTS = 0
export const ORDERS_FETCH_MAX_ATTEMPTS = 6
export const WAIT_TIME = 2000

export const BuildYourNextBox = ({
  discountSofteningFreeDeliveryValue,
  orders,
  userLoadOrders,
}: Props) => {
  const { trackClickOnChooseRecipes, trackSecondOrderIsLoaded } = useWelcomeTracking()
  const [hasOrderRetryFailed, setHasOrderRetryFailed] = React.useState<boolean>(false)
  const orderIds = Object.keys(orders)
  const secondaryOrder = orders[orderIds[1]]

  const tryFetchOrders = useCallback(() => {
    const intervalId = setInterval(() => {
      if (orderIds.length > 1) {
        clearInterval(intervalId)

        return
      }

      if (ORDERS_FETCH_CURRENT_ATTEMPTS <= ORDERS_FETCH_MAX_ATTEMPTS) {
        userLoadOrders(true)
        ORDERS_FETCH_CURRENT_ATTEMPTS += 1
      } else {
        clearInterval(intervalId)

        datadogLogs.logger.error(
          `Exceeded time to load orders after signing up. Order IDs: ${Object.keys(orders)}`,
        )

        setHasOrderRetryFailed(true)
        trackSecondOrderIsLoaded(false)
      }
    }, WAIT_TIME)

    return intervalId
  }, [orderIds.length, userLoadOrders, orders, trackSecondOrderIsLoaded])

  useEffect(() => {
    // sign up order only, then tries loading again until it finds a second order
    const intervalId = tryFetchOrders()

    return () => {
      clearInterval(intervalId)
    }
  }, [tryFetchOrders])

  useEffect(() => {
    if (secondaryOrder) {
      trackSecondOrderIsLoaded(true)
    }
  }, [trackSecondOrderIsLoaded, secondaryOrder])

  const handleChooseRecipes = useCallback(() => {
    trackClickOnChooseRecipes()

    browserHistory.push(`/menu/${secondaryOrder?.id}`)
  }, [secondaryOrder, trackClickOnChooseRecipes])

  if (hasOrderRetryFailed) {
    const signUpOrderId = orderIds[0]
    const signUpOrder = orders[signUpOrderId]
    const isNewDiscountUIEnabled = ['t1', 't3'].includes(discountSofteningFreeDeliveryValue)

    return (
      <>
        <Heading4 color={Color.ColdGrey_900}>What happens next?</Heading4>
        <Space direction="vertical" size={4} />
        <WhatHappenNext
          deliveryDate={signUpOrder.deliveryDate}
          whenCutoff={signUpOrder.shouldCutoffAt}
          interval="1"
          discountSofteningFreeDeliveryValue={discountSofteningFreeDeliveryValue}
          isFiveForFourOrder={
            signUpOrder.box.numRecipes === '5' && signUpOrder.box.numPortions === '4'
          }
          isNewDiscountUIEnabled={isNewDiscountUIEnabled}
          isValidWhenCutoff
          isValidDeliveryDate
          showOrderSummary={false}
        />
      </>
    )
  }

  if (!secondaryOrder) {
    return (
      <Box
        display={Display.Flex}
        justifyContent={JustifyContent.Center}
        alignItems={AlignItems.Center}
        data-testid="loading"
      >
        <Box width="3rem" height="3rem">
          <Loading />
        </Box>
      </Box>
    )
  }

  const percentageOff = parseInt(secondaryOrder.prices.percentageOff, 10)
  const deliveryDate = getFormattedDeliveryDate(secondaryOrder.deliveryDate)
  const hasDiscount = percentageOff > 0

  const { isDeliveryFree } = secondaryOrder.prices
  const { numPortions } = secondaryOrder.box

  const renderPill = (labelText: string) => (
    <Box
      borderRadius={1.5}
      display={Display.InlineBlock}
      bg={Color.Success_800}
      paddingH={1}
      paddingV={0.5}
    >
      <Body3 fontWeight={FontWeight.Bold} color={Color.White}>
        {labelText}
      </Body3>
    </Box>
  )

  return (
    <Box>
      <Join with={<Space direction="vertical" size={2} />}>
        <Join with={<Space direction="vertical" size={0.5} />}>
          <Heading4 color={Color.ColdGrey_900}>Build Your Next Box</Heading4>
          <Text size={1}>Scheduled for {deliveryDate}</Text>
        </Join>
        <Box>
          <Join with={<Space direction="horizontal" size={1} />}>
            {isDeliveryFree && renderPill('FREE delivery for a year')}
            {hasDiscount && renderPill(`${percentageOff}% OFF`)}
          </Join>
        </Box>
        <Box display={Display.Flex}>
          <Join with={<Space direction="horizontal" size={1} />}>
            <RecipeImageTiles orderId={secondaryOrder.id} numPortions={Number(numPortions)} />
          </Join>
        </Box>
        <Space direction="vertical" size={2} />
        <Box display={Display.Flex} flexDirection={FlexDirection.Column}>
          <Heading5 color={Color.ColdGrey_900}>Next box best sellers</Heading5>
          <Space direction="vertical" size={3} />
          <Carousel order={secondaryOrder} />
        </Box>
        <Button width="100%" onClick={handleChooseRecipes}>
          Choose recipes
        </Button>
      </Join>
    </Box>
  )
}
