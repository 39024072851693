import React, { useEffect, useRef } from 'react'

import {
  AlignItems,
  Box,
  Color,
  Display,
  FlexDirection,
  FontFamily,
  Heading3,
  JustifyContent,
  Link,
  Space,
  Text,
  TextAlign,
  useTheme,
} from '@gousto-internal/citrus-react'
import { TRUSTPILOT_SECTION } from '../../config'

type TrustPilotType = {
  loadFromElement: (element: HTMLElement | null, force: boolean) => void
}

export type TrustpilotWindow = typeof window & {
  Trustpilot: TrustPilotType
}

export const TrustPilotSection = () => {
  const ref = useRef(null)
  const { theme } = useTheme()

  const { size: h3FontSize, fontFamily: h3FontFamily } = theme.tokens.typographyDefaults.h3

  useEffect(() => {
    const win = window as TrustpilotWindow
    // widget may not load if the bootstrapper script has been loaded before the component gets rendered
    // we use the TrustPilot loadFromElement function to manually load the widget if this happens

    if (win.Trustpilot) {
      win.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [])

  return (
    <section data-testid="TrustPilotSection">
      <Box
        display={Display.Flex}
        flexDirection={[FlexDirection.Column, FlexDirection.Row]}
        alignItems={AlignItems.Center}
        justifyContent={JustifyContent.Center}
        bg={Color.NeutralGrey_50}
        paddingV={[10, null, 12]}
      >
        <Box flexGrow={1} width={['100%', 'auto']} maxWidth="1171px">
          <Box paddingH={8}>
            <Heading3 fontFamily={FontFamily.Bold} textAlign={[TextAlign.Left, TextAlign.Center]}>
              {TRUSTPILOT_SECTION.headingFirst}{' '}
              <Text
                display={Display.Inline}
                color={Color.Primary_600}
                fontFamily={h3FontFamily}
                size={h3FontSize}
              >
                {TRUSTPILOT_SECTION.headingSecond}{' '}
              </Text>
              {TRUSTPILOT_SECTION.headingThird}
            </Heading3>
          </Box>
          <Space size={5} />
          <Box
            ref={ref}
            className="trustpilot-widget"
            data-locale="en-GB"
            data-template-id="53aa8912dec7e10d38f59f36"
            data-businessunit-id="512cda6e000064000522fb6a"
            data-style-height="240px"
            data-style-width="100%"
            data-theme="light"
            data-stars="4,5"
            data-review-languages="en"
            data-font-family="Montserrat"
            data-text-color={theme.color(Color.ColdGrey_800)}
            data-testid="TrustPilotWidget"
            maxHeight={['260px', '150px']}
          >
            <Link href="https://uk.trustpilot.com/review/gousto.co.uk" target="_blank">
              Trustpilot
            </Link>
          </Box>
        </Box>
      </Box>
    </section>
  )
}
