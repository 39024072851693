import React from 'react'

import {
  Box,
  Display,
  JustifyContent,
  Text,
  TextAlign,
  Heading6,
  Modal,
  ModalHeader,
  ModalButton,
  ModalBody,
  ModalFooter,
  ButtonSize,
  ModalClose,
} from '@gousto-internal/citrus-react'

import { Clock } from 'routes/Checkout/Components/CheckoutUrgency/Clock/Clock'
import { useIsUrgentCheckoutTimerEnabled } from 'routes/Checkout/hooks'

import {
  expiredCheckoutUrgencyModalTextsControl,
  almostExpiredCheckoutUrgencyModalTextsControl,
  expiredCheckoutUrgencyModalTextsTreatment,
  almostExpiredCheckoutUrgencyModalTextsTreatment,
} from './data'

interface CheckoutUrgencyModalProps {
  onConfirmModal: () => void
  onDismissModal: () => void
  /**
   * if true, modal will show expired text
   * if false, modal will show almost expired text
   */
  isExpired: boolean
}
export const checkoutUrgencyModalName = 'checkoutUrgencyModal'
export const CheckoutUrgencyModal = ({
  onConfirmModal,
  onDismissModal,
  isExpired,
}: CheckoutUrgencyModalProps) => {
  const isUrgentCheckoutTimerEnabled = useIsUrgentCheckoutTimerEnabled()
  const getCheckoutUrgencyModalText = (isExpiredModal: boolean) =>
    isExpiredModal
      ? expiredCheckoutUrgencyModalTextsControl
      : almostExpiredCheckoutUrgencyModalTextsControl

  const urgencyModalTexts = getCheckoutUrgencyModalText(isExpired)

  const getCheckoutUrgencyModalTextForTreatment = (isExpiredModal: boolean) =>
    isExpiredModal
      ? expiredCheckoutUrgencyModalTextsTreatment
      : almostExpiredCheckoutUrgencyModalTextsTreatment

  const urgencyModalTextsTreatment = getCheckoutUrgencyModalTextForTreatment(isExpired)

  return (
    <Modal name={checkoutUrgencyModalName} preventScroll onRequestClose={onDismissModal}>
      <ModalHeader>
        <Heading6>
          {isUrgentCheckoutTimerEnabled
            ? urgencyModalTextsTreatment.headingText
            : urgencyModalTexts.headingText}
        </Heading6>
        <ModalClose onClose={onDismissModal} />
      </ModalHeader>
      <ModalBody>
        <Box display={Display.Flex} justifyContent={JustifyContent.Center} paddingBottom={6}>
          <Clock />
        </Box>
        <Text size={[2, 3]} textAlign={TextAlign.Center}>
          {isUrgentCheckoutTimerEnabled
            ? urgencyModalTextsTreatment.bodyText
            : urgencyModalTexts.bodyText}
        </Text>
      </ModalBody>
      <ModalFooter>
        <Box display={Display.Flex} justifyContent={JustifyContent.Center} width="100%">
          <ModalButton onClick={onConfirmModal} size={ButtonSize.Medium} width="70%">
            {isUrgentCheckoutTimerEnabled
              ? urgencyModalTextsTreatment.ctaText
              : urgencyModalTexts.ctaText}
          </ModalButton>
        </Box>
      </ModalFooter>
    </Modal>
  )
}
