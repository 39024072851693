import React from 'react'
import {
  Box,
  Display,
  withCitrusTheme,
  Image as CitrusImage,
  Text,
  FontWeight,
  AlignItems,
  JustifyContent,
  Icon,
  Color,
} from '@gousto-internal/citrus-react'
import { BasketRecipe } from '../../types'
import { getDefaultImage, getSrcSet } from '../../utils/formatters'

const ContainedImage = withCitrusTheme(CitrusImage, () => ({
  objectFit: 'contain',
  objectPosition: 'center',
  borderRadius: '3px',
}))

type RecipeProps = {
  recipe: BasketRecipe
  removeRecipe: (uuid: string) => void
}

export const Recipe = ({ recipe, removeRecipe }: RecipeProps) => {
  return (
    <Box
      key={recipe.uuid}
      display={Display.Flex}
      justifyContent={JustifyContent.FlexStart}
      alignItems={AlignItems.Center}
      gap={3}
      color={Color.ColdGrey_900}
      style={{ opacity: recipe.isOutOfStock ? 0.3 : 1 }}
    >
      <ContainedImage
        alt={recipe.name}
        loading="lazy"
        src={getDefaultImage(recipe.moodImage.crops)}
        srcSet={getSrcSet(recipe.moodImage.crops)}
        height="70px"
        width="70px"
      />
      <Box display={Display.Flex} alignItems={AlignItems.Center} flexGrow={1} width="100px">
        <Text fontWeight={FontWeight.Bold}>{recipe.name}</Text>
      </Box>
      {!recipe.isOutOfStock && (
        <Box role="button" onClick={() => removeRecipe(recipe.uuid)} style={{ cursor: 'pointer' }}>
          <Icon name="remove_rounded" />
        </Box>
      )}
    </Box>
  )
}
