import React from 'react'

import {
  Box,
  Color,
  Display,
  FontFamily,
  Icon,
  IconVariant,
  Image,
  Space,
  Text,
} from '@gousto-internal/citrus-react'
import { useDispatch, useSelector } from 'react-redux'

import { setCurrentPaymentMethod } from 'routes/Checkout/checkoutActions'
import { isPayPalReady } from 'routes/Checkout/checkoutPaymentSelectors'
import { isSubmitting as getIsSubmitting } from 'routes/Checkout/utils/state'
import { PaymentMethod } from 'routes/Signup/signupConfig'
import { onEnter } from 'utils/accessibility'

import paypalPaymentMethod from '../../../assets/paypal.png'
import { ErrorMessage } from '../../ErrorMessage/ErrorMessage'
import { SubmitButton } from '../../SubmitButton'
import { ResetPaymentMethod } from './PayPalConfirmation.styled'

type PayPalConfirmationProps = {
  submit: () => void
  onLoginClick: () => void
}

export const PayPalConfirmation = ({ submit, onLoginClick }: PayPalConfirmationProps) => {
  const dispatch = useDispatch()
  const isSubmitting = useSelector(getIsSubmitting)
  const isPayPalPaymentApproved = useSelector(isPayPalReady)
  const resetPaymentMethod = () => {
    dispatch(setCurrentPaymentMethod(PaymentMethod.Card))
  }

  const changePaymentMethodCTA = (
    <Box>
      <Text color={Color.ColdGrey_500} fontFamily={FontFamily.Bold} display={Display.Inline}>
        or&nbsp;
      </Text>
      <ResetPaymentMethod
        role="button"
        tabIndex={0}
        onClick={resetPaymentMethod}
        onKeyDown={onEnter(resetPaymentMethod)}
        data-testid="resetPaymentMethod"
      >
        <Text fontFamily={FontFamily.Bold} display={Display.Inline} data-testid="changePaymentText">
          Change payment method
        </Text>
        <Space size={10} direction="vertical" />
      </ResetPaymentMethod>
    </Box>
  )

  return (
    <>
      <Box display={Display.Flex} wrap>
        <Icon name="tick" variant={IconVariant.Confirmation} />
        <Space size={1} direction="horizontal" />
        <Text size={3} data-testid="paypalAlternateText">
          You’re set to pay with
        </Text>
        <Space size={1} direction="horizontal" />
        <Image src={paypalPaymentMethod} alt="paypal logo" width="100px" data-testid="paypalIcon" />
      </Box>

      <Space size={10} direction="vertical" />
      {isSubmitting || changePaymentMethodCTA}
      <ErrorMessage onLoginClick={onLoginClick} />
      <SubmitButton onClick={submit} isDisabled={!isPayPalPaymentApproved} />
    </>
  )
}
