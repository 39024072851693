import { RootStateOrAny } from 'react-redux'
import { Dispatch } from 'redux'

import {
  fetchProduct,
  fetchProductCategories,
  fetchProductStock,
  fetchProducts,
} from 'apis/products'
import { Product } from 'routes/OrderConfirmation/types/products'
import { getAccessToken, getAuthUserId } from 'selectors/auth'
import logger from 'utils/logger'
import { sortProductsByPrice } from 'utils/products'

import { actionTypes } from './actionTypes'
import statusActions from './status'

type GetState = () => RootStateOrAny

export const productsLoadCategories =
  (forceRefresh = false) =>
  async (dispatch: Dispatch<any>, getState: GetState) => {
    if (forceRefresh || !getState().productsCategories.size) {
      dispatch(statusActions.pending(actionTypes.PRODUCT_CATEGORIES_RECEIVE, true))
      try {
        const { data: categories } = await fetchProductCategories(
          getState().auth.get('accessToken'),
        )
        dispatch({ type: actionTypes.PRODUCT_CATEGORIES_RECEIVE, categories })
      } catch (err: any) {
        dispatch(statusActions.error(actionTypes.PRODUCT_CATEGORIES_RECEIVE, err.message))
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        logger.error(err)
      } finally {
        dispatch(statusActions.pending(actionTypes.PRODUCT_CATEGORIES_RECEIVE, false))
      }
    }
  }

function getProductParameters(state: RootStateOrAny) {
  const accessToken = getAccessToken(state)
  const authUserId = getAuthUserId(state)

  return {
    accessToken,
    authUserId,
  }
}

export const productsLoadProducts =
  (postcode: string, periodId: string, { reload = false } = {}) =>
  async (dispatch: Dispatch<any>, getState: GetState) => {
    const { basket, products, productsStock, error, features } = getState()
    const currentProductsInBasket = basket.get('products')
    const isProductsLargerThanBasket = products.size <= currentProductsInBasket.size
    const reqData = {
      postcode,
      sort: 'position',
      period_id: periodId,
    }

    const { accessToken, authUserId } = getProductParameters(getState())

    if (isProductsLargerThanBasket || reload) {
      dispatch(statusActions.pending(actionTypes.PRODUCTS_RECEIVE, true))
      try {
        const { data: productsFromApi } = await fetchProducts(accessToken, reqData, authUserId)
        const productsToDisplay = productsFromApi.reduce(
          (productsForSaleAccumulator: Product & { stock: number }[], product: Product) => {
            if (product.isForSale) {
              productsForSaleAccumulator.push({
                ...product,
                stock: productsStock.get(product.id),
              })
            }

            return productsForSaleAccumulator
          },
          [],
        )

        const shouldSortByPrice = features.getIn(['sortMarketProducts', 'value'], false)
        const productsToStore = shouldSortByPrice
          ? sortProductsByPrice(productsToDisplay)
          : productsToDisplay

        dispatch({
          type: actionTypes.PRODUCTS_RECEIVE,
          products: productsToStore,
          reload,
        })
        if (error[actionTypes.PRODUCTS_RECEIVE]) {
          dispatch(statusActions.error(actionTypes.PRODUCTS_RECEIVE, null))
        }
      } catch (err: any) {
        dispatch(statusActions.error(actionTypes.PRODUCTS_RECEIVE, err.message))
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        logger.error(err)
      } finally {
        dispatch(statusActions.pending(actionTypes.PRODUCTS_RECEIVE, false))
      }
    }
  }

export const productsLoadProductsById =
  (productIds: string[] = []) =>
  async (dispatch: Dispatch<any>, getState: GetState) => {
    const newProductIds = productIds
      .filter((productId) => !getState().products.has(productId))
      .sort()
    const { accessToken, authUserId } = getProductParameters(getState())

    if (newProductIds.length) {
      dispatch(statusActions.pending(actionTypes.PRODUCTS_RECEIVE, true))
      try {
        const productPromises = newProductIds.map(async (productId) => {
          const { data } = await fetchProduct(accessToken, productId, authUserId)

          return data
        })
        const products = await Promise.all(productPromises)

        dispatch({ type: actionTypes.PRODUCTS_RECEIVE, products })
      } catch (err: any) {
        dispatch(statusActions.error(actionTypes.PRODUCTS_RECEIVE, err.message))
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        logger.error(err)
      } finally {
        dispatch(statusActions.pending(actionTypes.PRODUCTS_RECEIVE, false))
      }
    }
  }

export const productsLoadStock =
  (forceRefresh = false) =>
  async (dispatch: Dispatch<any>, getState: GetState) => {
    if (forceRefresh || !getState().productsStock.size) {
      dispatch(statusActions.pending(actionTypes.PRODUCTS_STOCK_CHANGE, true))
      try {
        const { data: stockData } = await fetchProductStock(getState().auth.get('accessToken'))
        const stock: { [key: string]: number } = {}

        Object.keys(stockData).forEach((productId) => {
          stock[productId] = stockData[productId].number
        })

        dispatch({ type: actionTypes.PRODUCTS_STOCK_CHANGE, stock })
      } catch (err: any) {
        dispatch(statusActions.error(actionTypes.PRODUCTS_STOCK_CHANGE, err.message))
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        logger.error(err)
      } finally {
        dispatch(statusActions.pending(actionTypes.PRODUCTS_STOCK_CHANGE, false))
      }
    }
  }

export const trackProductFiltering = (
  eventName: string,
  eventAction: string,
  eventType: string,
  primaryCategory: string,
  productsPerCategory: number,
) => ({
  type: actionTypes.PRODUCTS_FILTER_TRACKING,
  trackingData: {
    actionType: eventName,
    eventName,
    eventAction,
    eventType,
    eventProperties: {
      categoryProperties: { primaryCategory, productsPerCategory },
    },
  },
})

export const productsActions = {
  productsLoadCategories,
  productsLoadProducts,
  productsLoadProductsById,
  productsLoadStock,
  trackProductFiltering,
}
