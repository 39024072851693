import React from 'react'

import {
  Box,
  Color,
  BorderStyle,
  Icon,
  Text,
  Display,
  IconVariant,
  AlignItems,
} from '@gousto-internal/citrus-react'

export const ExistingOrderAlert = () => (
  <Box
    alignItems={AlignItems.FlexStart}
    bg={Color.Informative_50}
    borderColor={Color.Informative_100}
    borderRadius={1}
    borderStyle={BorderStyle.Solid}
    borderWidth={0.5}
    data-testid="existing-order-alert"
    display={Display.Flex}
    gap={2}
    paddingH={4}
    paddingV={4}
  >
    <Icon name="info" variant={IconVariant.Informative} />
    <Box paddingV={0.5}>
      <Text fontWeight={700} size={3}>
        You have an existing order on this date
      </Text>
    </Box>
  </Box>
)
