import React from 'react'

import { Box, Space } from '@gousto-internal/citrus-react'
import moment from 'moment'

import OrderSummary from 'containers/welcome/OrderSummary'

import { DiscountTag } from '../DiscountTag/DiscountTag'
import { EventDate } from '../EventDate'

import css from '../OrderSchedule.css'

type Props = {
  deliveryDate: string
  whenCutoff: string
  interval: string
  discountSofteningFreeDeliveryValue: string
  isFiveForFourOrder: boolean
  isNewDiscountUIEnabled: boolean
  isValidWhenCutoff: boolean
  isValidDeliveryDate: boolean
  showOrderSummary: boolean
}

export const WhatHappenNext = ({
  deliveryDate,
  whenCutoff,
  interval,
  discountSofteningFreeDeliveryValue,
  isFiveForFourOrder,
  isNewDiscountUIEnabled,
  isValidWhenCutoff,
  isValidDeliveryDate,
  showOrderSummary,
}: Props) => {
  const firstOrderDate = moment(whenCutoff, 'YYYY-MM-DD').format('dddd Do MMMM')
  const secondOrderDate = moment(deliveryDate, 'YYYY-MM-DD').add(4, 'days') as unknown as string
  const thirdOrderDate = moment(deliveryDate, 'YYYY-MM-DD').add(7, 'days') as unknown as string

  return (
    <Box>
      <div className={css.scheduleItem}>
        <div>
          <EventDate date={isValidWhenCutoff ? deliveryDate : ''} />
          <div className={css.verticalLine} />
        </div>
        <div className={css.information}>
          <h3 className="first-order-header">Your 1st order</h3>
          <p className="first-order-text">
            Changes can be made up until <b>{isValidWhenCutoff ? firstOrderDate : ''}</b>
            {isFiveForFourOrder
              ? ". Heads up, your order could arrive in 1 or 2 boxes. We'll let you know when it's packed."
              : ''}
          </p>
          {showOrderSummary && (
            <div className={css.mobileShow}>
              <OrderSummary
                discountSofteningFreeDeliveryValue={discountSofteningFreeDeliveryValue}
                onOrderConfirmationMobile={false}
                isOrderConfirmation={false}
              />
            </div>
          )}
        </div>
      </div>
      <div className={css.scheduleItem}>
        <div>
          <EventDate date={isValidDeliveryDate ? secondOrderDate : ''} />
          <div className={css.verticalLine} />
        </div>
        <div className={css.information}>
          <h3 className="second-order-header">Your 2nd order cut-off</h3>
          {isNewDiscountUIEnabled && (
            <>
              <DiscountTag />
              <Space size={1} />
            </>
          )}
          <p>
            Pick your recipes
            <b> before 12pm </b>
            on this date (don’t worry we&apos;ll remind you).
            <b> If you miss this we&apos;ll choose for you.</b>
          </p>
        </div>
      </div>
      <div className={css.scheduleItem}>
        <div>
          <EventDate date={isValidDeliveryDate ? thirdOrderDate : ''} />
        </div>
        <div className={css.information}>
          <h3 className="second-order-delivery-header">Delivery of 2nd order</h3>
          <p className="second-order-delivery-text">
            Your
            <b>{` ${interval} `}</b>
            Gousto delivery will arrive every
            {` ${
              isValidDeliveryDate ? moment(deliveryDate, 'YYYY-MM-DD').format('dddd') : ''
            }. You'll get tracking information on the day.`}
          </p>
        </div>
      </div>
    </Box>
  )
}
