import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'

import { Box, Button, Display, Link, Select } from '@gousto-internal/citrus-react'
import { useFormikContext } from 'formik'
import { restrictedRegularExpressions } from 'validations/regularExpressions'

import { DeliveryPoint } from '@library/api-postcode-lookup'

import { AddressCard } from 'routes/Checkout/Components/PostcodeAddressFields/AddressCard'
import {
  HouseNoInput,
  PostcodeInput,
  StreetInput,
  TownInput,
} from 'routes/Checkout/Components/PostcodeAddressFields/Fields'
import { useCheckCanDeliver } from 'routes/Checkout/Components/PostcodeAddressFields/useCheckCanDeliver'
import { showAddress } from 'routes/Checkout/utils/delivery'

import { usePostcodeAddresses } from './usePostcodeAddresses'

export type AddressFields = {
  searchPostcode: string
  postcode: string
  houseNo: string
  street: string
  town: string
  county: string
  udprn: string
}

const emptyAddress: Partial<AddressFields> = {
  postcode: '',
  houseNo: '',
  street: '',
  town: '',
  county: '',
  udprn: '',
}

const sanitizeAddressFields = (
  input: string | undefined | null,
  regex: RegExp,
  maxLength: number,
): string => {
  if (!input) return ''

  return input.replace(regex, ' ').substring(0, maxLength)
}

const generateSelectOptions = (points: DeliveryPoint[]) => {
  if (points.length === 0) return [{ value: 'placeholder', label: 'No addresses found' }]

  const options = points.map((point) => {
    const addressParts = [point.line1, point.line2]

    const label = addressParts.filter(Boolean).join(', ')

    return { value: point.udprn, label }
  })

  return [{ value: 'placeholder', label: 'Please select your address' }, ...options]
}

export const PostcodeAddressFields = () => {
  const { values, errors, setValues, setFieldValue, setFieldError } =
    useFormikContext<AddressFields>()

  const selectedAddress = values.udprn ? showAddress(values) : undefined

  const [sentPostcode, setSentPostcode] = useState('')

  // this hook is called when sentPostcode changes which is:
  // - when the component mounts
  // - when the "Search" button is clicked
  const { addresses, isPostcodeError } = usePostcodeAddresses(sentPostcode)

  const [deliveryPoints, setDeliveryPoints] = useState<DeliveryPoint[]>([])
  const [isEditingManually, setIsEditingManually] = useState(
    !selectedAddress && (!!values.houseNo || !!values.town),
  )

  const { checkCanDeliver } = useCheckCanDeliver(
    (error) => setFieldError('searchPostcode', error),
    setDeliveryPoints,
    values.searchPostcode,
  )

  useEffect(() => {
    const onMount = async () => {
      await checkCanDeliver()
      setSentPostcode(values.searchPostcode.toUpperCase())
    }
    onMount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (addresses) {
      setDeliveryPoints(addresses)
      setFieldError('searchPostcode', '')
    }

    if (isPostcodeError) {
      setFieldError('searchPostcode', 'Please enter a valid UK postcode')
      setDeliveryPoints([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addresses, isPostcodeError])

  const onSelectFieldChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const point = deliveryPoints.find((p) => p.udprn === e.target.value)
      if (!point) return
      setValues({
        ...values,
        houseNo: sanitizeAddressFields(point.line1, restrictedRegularExpressions.houseNo, 70),
        street: sanitizeAddressFields(point.line2, restrictedRegularExpressions.houseNo, 35),
        town: sanitizeAddressFields(point.town, restrictedRegularExpressions.houseNo, 32),
        county: point.county,
        postcode: point.postcode,
        udprn: point.udprn,
      })
    },
    [deliveryPoints, setValues, values],
  )

  const onSearchButtonClick = useCallback(async () => {
    setSentPostcode(values.searchPostcode.toUpperCase())
    await checkCanDeliver()
    setValues({ ...values, ...emptyAddress })
    setIsEditingManually(false)
  }, [checkCanDeliver, setValues, values])

  return (
    <>
      <Box display={Display.Flex}>
        <Box
          flexGrow={1}
          flexShrink={1}
          flexBasis="100%"
          maxWidth="16.25rem"
          paddingRight={[3, 4]}
          paddingBottom={[3, 4]}
        >
          <PostcodeInput />
        </Box>

        <Button
          type="button"
          style={{ marginTop: '2rem' }}
          height="3rem"
          width="auto"
          data-testid="checkoutFindAddressButton"
          disabled={!!errors.searchPostcode || values.searchPostcode.toUpperCase() === sentPostcode}
          onClick={onSearchButtonClick}
        >
          Search
        </Button>
      </Box>

      {!!selectedAddress && (
        <AddressCard
          address={selectedAddress}
          onEditAddressManuallyClick={() => {
            setIsEditingManually(true)
            setFieldValue('udprn', '')
          }}
        />
      )}

      {!selectedAddress && isEditingManually && (
        <>
          <Box paddingBottom={[4, 6]}>
            <HouseNoInput />
          </Box>
          <Box paddingBottom={[4, 6]}>
            <StreetInput />
          </Box>
          <Box paddingBottom={[4, 6]}>
            <TownInput />
          </Box>
        </>
      )}

      {!selectedAddress && !isEditingManually && (
        <>
          <Select
            data-testid="checkoutAddressDropdown"
            style={{ maxWidth: '100%', marginBottom: '1rem' }}
            onChange={onSelectFieldChange}
          >
            {generateSelectOptions(deliveryPoints ?? []).map((option) => (
              <option
                key={option.value}
                value={option.value}
                selected={option.value === 'placeholder'}
              >
                {option.label}
              </option>
            ))}
          </Select>

          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            style={{ marginBottom: '1.5rem' }}
            size={1}
            role="button"
            onClick={() => {
              setIsEditingManually(true)
              setFieldValue('postcode', values.searchPostcode)
            }}
          >
            Enter address manually
          </Link>
        </>
      )}
    </>
  )
}
