import React, { useCallback } from 'react'

import {
  Heading4,
  Text,
  Box,
  Button,
  withCitrusTheme,
  JustifyContent,
  Space,
  ButtonColorVariant,
} from '@gousto-internal/citrus-react'
import Svg from 'Svg'

import * as appsConfig from '../../../config/apps'
import * as routesConfig from '../../../config/routes'

type Props = {
  trackWelcomeAppPromoClick: () => void
  isSignUpFirstTwoBoxesExperimentEnabled: boolean
}

const AppSvg = withCitrusTheme(Svg, () => ({
  width: '100%',
  height: '50px',
}))

const AppBox = withCitrusTheme(Box, () => ({
  width: '170px',
  cursor: 'pointer',
}))

const AppPromo = ({ trackWelcomeAppPromoClick, isSignUpFirstTwoBoxesExperimentEnabled }: Props) => {
  const playStoreLink = `https://play.google.com/store/apps/details?id=${appsConfig.playStoreId}&utm_source=global_co&utm_medium=prtnr&utm_content=Mar2515&utm_campaign=PartBadge&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1`
  const appStoreLink = `https://geo.itunes.apple.com/gb/app/gousto-uks-no1-recipe-kit/id${appsConfig.appStoreId}?mt=8`

  const clickHandler = useCallback(
    (redirectTo: string) => () => {
      trackWelcomeAppPromoClick()

      window.location.assign(redirectTo)
    },
    [trackWelcomeAppPromoClick],
  )

  return (
    <Box data-testing="appPromo" width="100%">
      <Heading4>Download the Gousto app</Heading4>
      <Text>See your orders and make changes at the click of a button</Text>
      <div>
        {/* mobile view */}
        <Box display={isSignUpFirstTwoBoxesExperimentEnabled ? 'block' : 'none'}>
          <Space size={4} direction="vertical" />
          <Button
            colorVariant={ButtonColorVariant.Secondary}
            data-testing="appPromoCTA"
            onClick={clickHandler(routesConfig.client.appsRedirect)}
            width="100%"
          >
            Download the app
          </Button>
        </Box>
        <Space size={4} direction="vertical" />
        {/* tablet and desktop view */}
        {isSignUpFirstTwoBoxesExperimentEnabled ? null : (
          <Box display={['none', 'flex']} justifyContent={JustifyContent.SpaceAround}>
            <AppBox
              role="button"
              aria-label="Download the app on Play Store"
              onClick={clickHandler(playStoreLink)}
            >
              <AppSvg label="play store" fileName="icon-Playstore-bw" className="" hidden={false} />
            </AppBox>
            <AppBox
              role="button"
              aria-label="Download the app on App Store"
              onClick={clickHandler(appStoreLink)}
            >
              <AppSvg label="app store" fileName="icon-Appstore-bw" className="" hidden={false} />
            </AppBox>
          </Box>
        )}
      </div>
    </Box>
  )
}

export { AppPromo }
