import React from 'react'

import {
  Body2,
  Box,
  Color,
  Display,
  DisplayPosition,
  FlexDirection,
  FontWeight,
  Heading3,
  Icon,
  IconVariant,
  Image,
  JustifyContent,
  Space,
  TextAlign,
} from '@gousto-internal/citrus-react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { menuPreferencesVisibilityChange } from 'actions/menu'
import { incrementTutorialViewed, tutorialTracking } from 'actions/tutorial'
import { useAuth } from 'routes/Menu/domains/auth'
import { CollectionId, useCurrentCollectionId } from 'routes/Menu/domains/collections'
import { onSpaceOrEnter } from 'utils/accessibility'

import caloriesEducationImage from 'media/photos/calories-education.jpg'

import css from './CaloriesTutorial.css'

const supportedCollectionIds = [CollectionId.AllRecipes, CollectionId.Recommendations]

export const CALORIES_TUTORIAL_NAME = 'recipe_tile_calories'

export const CaloriesTutorial = () => {
  const dispatch = useDispatch()
  const { isAuthenticated } = useAuth()
  const currentCollectionId = useCurrentCollectionId()
  const showMenuPreferences = () => dispatch(menuPreferencesVisibilityChange(true))

  const hasDismissedTutorial = useSelector(({ tutorial }: RootStateOrAny) =>
    Boolean(tutorial && tutorial.getIn(['viewed', CALORIES_TUTORIAL_NAME])),
  )

  if (
    !isAuthenticated ||
    !supportedCollectionIds.includes(currentCollectionId ?? '') ||
    hasDismissedTutorial
  ) {
    return null
  }

  const onClose = () => {
    dispatch(incrementTutorialViewed(CALORIES_TUTORIAL_NAME))
    dispatch(tutorialTracking(CALORIES_TUTORIAL_NAME, 0, true))
  }

  return (
    <Box
      bg={Color.White}
      data-testid="calories-tutorial"
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
    >
      <Box
        bg={Color.White}
        display={Display.Flex}
        onClick={onClose}
        onKeyDown={onClose}
        paddingH={1}
        paddingV={1}
        position={DisplayPosition.Absolute}
        right="12px"
        role="button"
        top="12px"
      >
        <Icon name="close" size={6} variant={IconVariant.Actionable}>
          Close calories education
        </Icon>
      </Box>
      <Box bg={Color.Secondary_50} display={Display.Flex} justifyContent={JustifyContent.Center}>
        <Box maxWidth="380px" minHeight="230px">
          <Image alt="Calories education" src={caloriesEducationImage} />
        </Box>
      </Box>
      <Box display={Display.Flex} flexDirection={FlexDirection.Column} paddingH={4} paddingV={4}>
        <Heading3 textAlign={TextAlign.Center}>Not interested in calories?</Heading3>
        <Space size={2} />
        <Box display={Display.Flex} paddingH={[8, 8, 12, 12]}>
          <Body2 textAlign={TextAlign.Center}>
            We&apos;re showing calories on recipes. Visit
            <Space direction="horizontal" size={1} />
            <Box
              display={Display.Inline}
              onClick={showMenuPreferences}
              onKeyDown={onSpaceOrEnter(showMenuPreferences)}
              role="button"
              tabIndex={0}
            >
              <Body2
                color={Color.Secondary_400}
                display={Display.Inline}
                fontWeight={FontWeight.Bold}
              >
                Preferences
                <Space direction="horizontal" size={1} />
                <Icon
                  className={css.menuPreferencesIcon}
                  name="preferences"
                  size={4}
                  variant={IconVariant.Actionable}
                >
                  Menu preferences
                </Icon>
              </Body2>
            </Box>
            <Space direction="horizontal" size={1} />
            (top right) if you want to hide them.
          </Body2>
        </Box>
      </Box>
    </Box>
  )
}
