import React, { useState } from 'react'
import {
  Box,
  Heading3,
  ModalHeader,
  ModalFooter,
  ModalButton,
  ButtonColorVariant,
  ButtonSize,
  Display,
  FlexDirection,
  ModalBody,
  JustifyContent,
  Color,
  Overflow,
  Divider,
} from '@gousto-internal/citrus-react'
import { BasketRecipe } from '../../types'
import { Info } from '../Info/Info'
import { Recipe } from '../Recipe/Recipe'

type ContentProps = {
  maxNumberOfRecipes: number
  numberOfPortionsPerBox: number
  recipes: BasketRecipe[]
  submitRemovedRecipes: (recipes: string[]) => void
  goBack: () => void
}

export const Content = ({
  maxNumberOfRecipes,
  numberOfPortionsPerBox,
  recipes,
  submitRemovedRecipes,
  goBack,
}: ContentProps) => {
  const outOfStockRecipes = recipes.filter((recipe) => recipe.isOutOfStock)
  const isAnyRecipeOutOfStock = outOfStockRecipes.length > 0

  const [removedRecipeIds, setRemovedRecipeIds] = useState<string[]>(
    outOfStockRecipes.map((recipe) => recipe.uuid),
  )

  const validRecipes = recipes.filter((recipe) => !removedRecipeIds.includes(recipe.uuid))

  const isOverLimitBy = validRecipes.length - maxNumberOfRecipes

  const [hasError, setHasError] = useState(isOverLimitBy > 0 || isAnyRecipeOutOfStock)

  const removeRecipe = (uuid: string) => {
    const newValidRecipes = validRecipes.filter((recipe) => recipe.uuid !== uuid)

    setRemovedRecipeIds([...removedRecipeIds, uuid])

    if (newValidRecipes.length === maxNumberOfRecipes) {
      setHasError(false)
    }
  }

  const sortedRecipes = [...outOfStockRecipes, ...validRecipes]

  return (
    <>
      <ModalHeader>
        <Heading3>Changes to basket</Heading3>
      </ModalHeader>
      <ModalBody paddingV={0}>
        <Box
          display={Display.Flex}
          flexDirection={FlexDirection.Column}
          justifyContent={JustifyContent.FlexStart}
          flexGrow={1}
          paddingV={4}
          height={['60vh']}
          overflowY={Overflow.Scroll}
        >
          <Info
            hasError={hasError}
            isAnyRecipeOutOfStock={isAnyRecipeOutOfStock}
            numberOfPortionsPerBox={numberOfPortionsPerBox}
            maxNumberOfRecipes={maxNumberOfRecipes}
            isOverLimitBy={isOverLimitBy}
          />
          <Box
            display={Display.Flex}
            flexDirection={FlexDirection.Column}
            width="100%"
            height="100%"
            gap={2}
            paddingV={4}
            data-testid="recipes"
          >
            {sortedRecipes.map((recipe) => (
              <Recipe
                key={recipe.uuid}
                recipe={recipe}
                removeRecipe={() => removeRecipe(recipe.uuid)}
              />
            ))}
          </Box>
        </Box>
      </ModalBody>
      <Divider paddingV={0} color={Color.ColdGrey_200} />
      <ModalFooter>
        <Box
          display={Display.Flex}
          gap={2}
          flexDirection={FlexDirection.Column}
          bg={Color.White}
          width="100%"
          paddingH={0}
        >
          <ModalButton
            colorVariant={ButtonColorVariant.Primary}
            size={ButtonSize.Large}
            onClick={() => submitRemovedRecipes(removedRecipeIds)}
            disabled={isOverLimitBy > 0}
            height="48px"
          >
            Update basket
          </ModalButton>
          <ModalButton
            colorVariant={ButtonColorVariant.Secondary}
            size={ButtonSize.Large}
            onClick={goBack}
            height="48px"
          >
            Back
          </ModalButton>
        </Box>
      </ModalFooter>
    </>
  )
}
