import React from 'react'

import {
  AlignItems,
  Box,
  Color,
  Display,
  FlexDirection,
  FontFamily,
  JustifyContent,
  Text,
  withCitrusTheme,
} from '@gousto-internal/citrus-react'

import { CSSObject } from '@emotion/styled'

type RecipePlaceholderProps = {
  basketPosition: number
  pricePerServing?: string | null
}

const TextWithStyling = withCitrusTheme(Text, (theme) => {
  return {
    textAlign: 'center',
    lineHeight: '12px  !important',
    fontSize: '10px !important',
    ...(theme.responsiveStyle(['normal', 'nowrap'], ['whiteSpace']) as CSSObject),
  }
})

export const RecipePlaceholder = ({ basketPosition, pricePerServing }: RecipePlaceholderProps) => {
  return (
    <Box
      alignItems={AlignItems.Center}
      bg={Color.NeutralGrey_50}
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      justifyContent={JustifyContent.Center}
      paddingH={1}
      paddingV={1}
      height={['60px', '50px']}
      width="100%"
    >
      <Text fontFamily={FontFamily.Bold} size={3}>
        {basketPosition}
      </Text>
      {pricePerServing && (
        <Box>
          <Box display={Display.Flex} justifyContent={JustifyContent.Center} gap={1}>
            <TextWithStyling display={[Display.None, Display.Inline]}>for</TextWithStyling>
            <TextWithStyling>£{pricePerServing}</TextWithStyling>
          </Box>
          <TextWithStyling>per serving</TextWithStyling>
        </Box>
      )}
    </Box>
  )
}
