import React, { ChangeEvent } from 'react'

import styled from '@emotion/styled'
import {
  AlignItems,
  Box,
  Display,
  FlexDirection,
  Space,
  RadioGroup,
  Heading5,
  Bold,
  Icon,
  IconVariant,
  Color,
  BorderStyle,
  Text,
  withCitrusTheme,
} from '@gousto-internal/citrus-react'

import {
  PortionSizes,
  PortionSizesConfig,
  PortionSizeDescriptor,
  pricingTransparencyPortionSizesConfig,
} from 'config/portionSizes'

import { PortionSizeSelectorImage } from './PortionSizeSelectorImage/PortionSizeSelectorImage'

const StyledRadioContainer = styled.div`
  label {
    align-items: flex-start;
  }
`

type PortionSizeSelectorProps = {
  portionSize: PortionSizes
  availablePortionSizes: PortionSizes[]
  config?: PortionSizesConfig
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const recipeCardInfoTip = {
  title: 'Heads up:',
  body: `You'll find measurements and instructions for this box size on your Gousto app, not on your recipe cards.`,
}

const Strikethrough = withCitrusTheme(Text, () => ({
  textDecoration: 'line-through',
}))

const RecipeCardInfoTip = () => (
  <>
    <Box
      data-testid="recipeCardInfoTip"
      bg={Color.Informative_50}
      display={Display.Flex}
      paddingH={4}
      paddingV={4}
      alignItems={AlignItems.FlexStart}
      borderColor={Color.Informative_50}
      borderWidth={0.5}
      borderStyle={BorderStyle.Solid}
      borderRadius={1.5}
      gap={2}
    >
      <Icon name="info" variant={IconVariant.Informative} />
      <Box>
        <Text size={2}>
          <Bold>{recipeCardInfoTip.title}</Bold>
          &nbsp;{recipeCardInfoTip.body}
        </Text>
      </Box>
    </Box>
  </>
)

export const PricingTransparencyPortionSelector = ({
  portionSize,
  availablePortionSizes,
  onChange,
  config = pricingTransparencyPortionSizesConfig,
}: PortionSizeSelectorProps) => {
  const getRadioLabel = ({
    heading,
    suitableFor,
    recipeInfoStrongText,
    value,
    originalPrice,
    discountedPrice,
  }: PortionSizeDescriptor) => {
    const packagingInfo = ' recipes.'
    const numberOfRecipes = recipeInfoStrongText

    return (
      <Box width="100%">
        <Box
          display={Display.Flex}
          flexDirection={FlexDirection.Column}
          paddingH={3}
          data-testid={`${heading}`}
          data-testing="pricingTransparencyPortionSizeView"
        >
          <Heading5 color={value === portionSize ? Color.Secondary_400 : Color.ColdGrey_800}>
            {heading}
          </Heading5>
          <Box display={value === portionSize ? Display.Block : Display.None}>
            <Space size={1} direction="vertical" />
            <Text size={[2, 4]} color={Color.ColdGrey_800}>
              Starting at{' '}
              <Strikethrough size={[2, 4]} display={Display.Inline}>
                £{originalPrice}
              </Strikethrough>{' '}
              <Bold size={4} display={Display.Inline} color={Color.Success_800}>
                £{discountedPrice}
              </Bold>{' '}
              for 2 recipes.
            </Text>

            <Text size={[2, 4]} color={Color.ColdGrey_800}>
              {`Choose `}
              <Bold>{numberOfRecipes}</Bold>
              {packagingInfo}
            </Text>
            <Space size={1} direction="vertical" />
            <Box>
              <Text size={[2, 4]} color={Color.ColdGrey_800}>
                Each recipe feeds:
              </Text>
              <Space size={1} direction="vertical" />
              {suitableFor.map((label: string) => (
                <Box
                  key={`radio-suitable-for-${label}`}
                  display={Display.Flex}
                  alignItems={AlignItems.Center}
                >
                  <Icon name="tick" variant={IconVariant.Confirmation} size={4} />
                  <Space direction="horizontal" size={2} />
                  <Text size={[2, 4]} color={Color.ColdGrey_800}>
                    {label}
                  </Text>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
        <Space size={2} />
        <Box display={value === portionSize ? Display.Block : Display.None}>
          <PortionSizeSelectorImage portionSize={portionSize} />
        </Box>
      </Box>
    )
  }

  const getRadioOptions = () =>
    availablePortionSizes.map((portion) => ({
      name: 'portionSize',
      value: config[portion].value,
      label: () => getRadioLabel(config[portion]),
    }))

  return (
    <>
      <StyledRadioContainer>
        <RadioGroup
          value={portionSize}
          outline
          options={getRadioOptions()}
          onChange={(e) => onChange(e)}
        />
      </StyledRadioContainer>
      <Space direction="vertical" size={2} />
      {[1, 5].includes(portionSize) && <RecipeCardInfoTip />}
    </>
  )
}
