import React from 'react'

import { InfoWrapper } from './InfoWrapper'
import { InfoMessage } from './InfoMessage'
import { Color, IconVariant } from '@gousto-internal/citrus-react'

export const InfoSuccess = () => {
  return (
    <InfoWrapper
      background={Color.Success_50}
      borderColor={Color.Success_200}
      testId="info-success"
    >
      <InfoMessage
        type="success"
        messages={['You can now successfully check out.']}
        iconVariant={IconVariant.Confirmation}
      />
    </InfoWrapper>
  )
}
