import { useSelector } from 'react-redux'

import { actionTypes } from 'actions/actionTypes'
import { createGetErrorForActionType } from 'selectors/status'

export const useCheckoutError = () => {
  const orderSaveError = useSelector(createGetErrorForActionType(actionTypes.ORDER_SAVE))
  const hadOrderSaveError = Boolean(orderSaveError) && orderSaveError !== 'no-stock'
  const basketExpiredError = orderSaveError === 'basket-expired'

  const stockErrorMessage =
    typeof orderSaveError === 'string' && orderSaveError.includes('following recipes are sold-out')
      ? orderSaveError
      : undefined

  const basketExpiredErrorMessage = basketExpiredError
    ? 'Sorry, your box has expired. Please re-add your recipe choices to continue'
    : undefined

  const showError = hadOrderSaveError || basketExpiredError
  const errorMessage = stockErrorMessage || basketExpiredErrorMessage

  return {
    showError,
    errorMessage,
  }
}
