import { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'

import {
  timeLeftToDisplayModal,
  timeToTimeoutSessionControl,
  timeToTimeoutSessionTreatment,
} from 'routes/Checkout/Components/CheckoutUrgency/checkoutUrgencyConstants'
import { getCheckoutStartedTime } from 'routes/Checkout/checkoutSelectors'
import { useIsUrgentCheckoutTimerEnabled } from 'routes/Checkout/hooks'

/**
 * Interval time (ms) for urgency banner to update "timeLeft" label.
 */
const TICK_INTERVAL = 1000

/**
 * Returns time left to check out in milliseconds.
 * Hook re-renders every TICK_INTERVAL milliseconds.
 */
export const useMillisecondsLeftToCheckout = (): number => {
  const isUrgentCheckoutTimerEnabled = useIsUrgentCheckoutTimerEnabled()

  const timeToTimeoutSession = isUrgentCheckoutTimerEnabled
    ? timeToTimeoutSessionTreatment
    : timeToTimeoutSessionControl

  const [timeLeft, setTimeLeft] = useState(timeToTimeoutSession)
  const checkoutStartedTime = useSelector(getCheckoutStartedTime)

  useEffect(() => {
    const updateTimeLeft = () => {
      const timePassed = new Date().getTime() - (checkoutStartedTime as number)
      const newTimeLeft = timeToTimeoutSession - timePassed
      setTimeLeft(newTimeLeft)
    }

    if (checkoutStartedTime) {
      updateTimeLeft()
      const timerId = setInterval(() => {
        updateTimeLeft()
      }, TICK_INTERVAL)

      return () => {
        clearInterval(timerId)
      }
    }

    return undefined
  }, [checkoutStartedTime, timeToTimeoutSession])

  return timeLeft
}

/**
 * Formats amount of time to `mm:SS` format.
 * @time - time in milliseconds.
 */
export const millisecondsToClockTime = (time: number): string => {
  if (time <= 0) {
    return '0:00'
  }
  const minutes = Math.floor(time / 1000 / 60)
  const seconds = Math.trunc((time / 1000) % 60)

  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
}

/**
 * Returns time left to check out in `mm:SS` format.
 * Hook re-renders every TICK_INTERVAL milliseconds.
 */
export const useTimeLeftToCheckOut = (): string => {
  const timeLeft = useMillisecondsLeftToCheckout()

  return millisecondsToClockTime(timeLeft)
}

/**
 * Returns true, if checkout session is expired and user need to go back to menu and start all over again.
 */
export const useIsCheckoutSessionExpired = (): boolean => {
  const timeLeft = useMillisecondsLeftToCheckout()

  return timeLeft < 0
}

/**
 * Returns true, if checkout session is almost expired; it is time to show modal to a user.
 */
export const useIsCheckoutSessionAlmostExpired = (): boolean => {
  const timeLeft = useMillisecondsLeftToCheckout()

  return timeLeft < timeLeftToDisplayModal && timeLeft > 0
}

/**
 * Function to format timeLeft into minutes or seconds.
 */
export const formatTimeLeft = (timeLeft: string) => {
  const [minutes, seconds] = timeLeft.split(':').map(Number)

  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds

  if (minutes === 0) {
    return { time: `${seconds}`, unit: 'seconds' }
  }

  const unit = minutes === 1 && seconds === 0 ? 'minute' : 'minutes'

  return { time: `${minutes}:${formattedSeconds}`, unit }
}
