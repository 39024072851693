import React, { useEffect, useState } from 'react'

import {
  Heading4,
  Modal,
  ModalBody,
  ModalButton,
  ModalClose,
  ModalFooter,
  ModalHeader,
  useModal,
} from '@gousto-internal/citrus-react'
import { PortionSizeSelector } from 'PortionSizeSelector/PortionSizeSelector'
import { useDispatch, useSelector } from 'react-redux'
import { browserHistory } from 'react-router'

import { queueTrackingEvent } from '@library/tracking-integration'

import { basketNumPortionChange } from 'actions/basket'
import { availablePortions, PortionSizes } from 'config/portionSizes'
import { useBasketImprovements } from 'hooks/useBasketImprovements'
import { DELIVERY_BOX_SIZE_MODAL_NAME } from 'routes/DeliveryDetails/constants'
import { getNumPortions } from 'selectors/basket'

type DeliveryBoxSizeModalProps = {
  orderId?: string
  orderNumPortions?: number
}

export const DeliveryBoxSizeModal = ({ orderId, orderNumPortions }: DeliveryBoxSizeModalProps) => {
  const { closeCurrentModal } = useModal()
  const dispatch = useDispatch()
  const basketNumPortions = useSelector(getNumPortions)
  const isBasketImprovementsEnabled = useBasketImprovements()

  const [savedBoxSize, setSavedBoxSize] = useState<PortionSizes>(basketNumPortions)
  const [selectedBoxSize, setSelectedBoxSize] = useState<PortionSizes>(basketNumPortions)

  useEffect(() => {
    const boxSize = orderNumPortions ? Number(orderNumPortions) : basketNumPortions
    setSavedBoxSize(boxSize)
    setSelectedBoxSize(boxSize)
  }, [basketNumPortions, orderNumPortions])

  const handleBoxSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newBoxSize = Number(event.target.value) as PortionSizes
    setSelectedBoxSize(newBoxSize)
  }

  const onConfirm = () => {
    if (selectedBoxSize !== savedBoxSize) {
      queueTrackingEvent<{
        action: 'confirm_num_portions'
        properties: {
          num_portions: PortionSizes
        }
      }>({
        action: 'confirm_num_portions',
        properties: {
          num_portions: selectedBoxSize,
        },
      })

      dispatch(basketNumPortionChange(selectedBoxSize, 'delivery_details'))

      if (isBasketImprovementsEnabled && orderId) {
        browserHistory.push(`/menu/${orderId}?selected_box_size=${selectedBoxSize}`)
      }
    }
    closeCurrentModal()
  }

  return (
    <Modal name={DELIVERY_BOX_SIZE_MODAL_NAME} onRequestClose={() => closeCurrentModal()}>
      <ModalClose onClose={() => closeCurrentModal()} />
      <ModalHeader>
        <Heading4>Edit box size</Heading4>
      </ModalHeader>
      <ModalBody>
        <PortionSizeSelector
          portionSize={selectedBoxSize}
          availablePortionSizes={availablePortions as PortionSizes[]}
          onChange={(e) => handleBoxSizeChange(e)}
        />
      </ModalBody>
      <ModalFooter>
        <ModalButton
          aria-label="Confirm box size"
          onClick={onConfirm}
          style={{ padding: '16px' }}
          width="100%"
        >
          Confirm box size
        </ModalButton>
      </ModalFooter>
    </Modal>
  )
}
