import React from 'react'

import { InfoWrapper } from './InfoWrapper'
import { InfoMessage } from './InfoMessage'
import { Color, IconVariant } from '@gousto-internal/citrus-react'

export const InfoWarning = ({ messages }: { messages: string[] }) => {
  return (
    <InfoWrapper
      background={Color.Warning_50}
      borderColor={Color.Warning_100}
      testId="info-warning"
    >
      <InfoMessage type="warning" messages={messages} iconVariant={IconVariant.Error} />
    </InfoWrapper>
  )
}
