import React, { SyntheticEvent } from 'react'

import {
  AlignItems,
  Badge,
  Box,
  Button,
  Color,
  Display,
  withCitrusTheme,
  Text,
  FontWeight,
  Spinner,
  JustifyContent,
} from '@gousto-internal/citrus-react'

const CustomButton = withCitrusTheme(Button, (theme) => ({
  '&:disabled, &:disabled:hover, &:disabled:focus': {
    color: theme.color(Color.White),
  },
}))

const PriceBadge = withCitrusTheme(Badge, (theme) => ({
  backgroundColor: theme.color(Color.Secondary_600),
  color: theme.color(Color.White),
  padding: theme.spacing(2),
  minWidth: theme.spacing(14),
  minHeight: theme.spacing(8),
  display: Display.Flex,
  justifyContent: JustifyContent.Center,
}))

type CheckoutButtonProps = {
  disabled?: boolean
  onClick: (e: SyntheticEvent) => void
  totalPrice?: string | null
}

export const CheckoutButton = ({ disabled, onClick, totalPrice }: CheckoutButtonProps) => (
  <CustomButton
    disabled={disabled}
    height="50px"
    onClick={onClick}
    width={['100%', '100%', '100%', '185px']}
  >
    <Box alignItems={AlignItems.Center} display={Display.Flex}>
      <Box paddingRight={2}>Checkout</Box>
      {!disabled && (
        <PriceBadge>
          {totalPrice ? (
            <Text fontWeight={FontWeight.Bold} size={0} display={Display.Flex}>
              £{totalPrice}
            </Text>
          ) : (
            <Spinner color={Color.White} size={3} />
          )}
        </PriceBadge>
      )}
    </Box>
  </CustomButton>
)
