import React from 'react'

import {
  AlignItems,
  Body2,
  Box,
  Color,
  Display,
  DisplayPosition,
  FlexDirection,
  FontFamily,
  Heading6,
  JustifyContent,
  Space,
  Text,
  TextAlign,
  withCitrusTheme,
} from '@gousto-internal/citrus-react'

import { MenuRecipeImage } from '@library/menu-service'

const RecipeTileContainer = withCitrusTheme(Box, () => ({
  width: '100%',
  height: '95%',
  display: 'flex',
  flexDirection: 'column',
  color: Color.White,
  position: 'relative',
  borderRadius: '4px',
  boxShadow: '4px 4px 8px 0px #0000001A',
  backgroundColor: Color.White,
}))

const ImageContainer = withCitrusTheme(Box, () => ({
  height: '200px',
  position: 'relative',
  overflow: 'hidden',
  borderRadius: '4px 4px 0  0 ',
}))

const StyledImage = withCitrusTheme('img', () => ({
  position: DisplayPosition.Absolute,
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  transform: 'scale(1.1)',
}))

const LabelWrapperBox = withCitrusTheme(Box, (theme) => ({
  display: Display.Flex,
  borderColor: theme.tokens.colors.White,
  borderWidth: 1.5,
  alignItems: AlignItems.Center,
  position: DisplayPosition.Absolute,
  top: theme.spacing(4),
  left: theme.spacing(4),
  transform: 'translateY(calc(-50% - 1rem))',
}))

export const DietaryIcons = withCitrusTheme(Text, (theme) => ({
  zIndex: 1,
  lineHeight: '14px !important',
  width: '28px',
  textAlign: 'center',
  color: theme.tokens.colors.White,
  position: 'relative',
  marginRight: theme.spacing(1),
  transform: 'translateY(8px)',
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    transform: 'translateY(-8px)',
    borderRadius: '50%',
    width: '28px',
    height: '28px',
    backgroundColor: theme.tokens.colors.ColdGrey_700,
    zIndex: -1,
  },
}))

type Image = {
  type: string
  crops: {
    url: string
    width: number
  }[]
}

type DietaryClaims = {
  slug: string
}

type Props = {
  calories: number
  dietaryClaims: DietaryClaims[]
  images: MenuRecipeImage[]
  prepTimes: number | null
  tag: string
  title: string
}

const ImageTypes = {
  MoodImage: 'mood-image',
}

const findImageUrls = (recipeImages: Image[]) => {
  const firstImageUrls = recipeImages[0]?.crops || []
  const moodImage = recipeImages.find((image) => image.type === ImageTypes.MoodImage)
  const recipeImage = moodImage ? moodImage.crops : firstImageUrls

  return recipeImage
}

const getDefaultImage = (srcs: ReturnType<typeof findImageUrls>) => {
  if (srcs.length === 0) {
    return null
  }

  const sortedSrcs = srcs.slice().sort((a, b) => b.width - a.width)
  const midpointInArray = Math.floor(sortedSrcs.length / 2)

  return sortedSrcs[midpointInArray]?.url || ''
}

const dietaryAcronyms = {
  vegan: 'PB',
  vegetarian: 'V',
  dairy_free: 'DF',
  gluten_free: 'GF',
}

const getDietaryClaims = (dietaryClaims: DietaryClaims[]) => {
  if (!dietaryClaims) {
    return []
  }

  const streamlinedDietaryClaims = ['dairy_free', 'vegan', 'vegetarian', 'gluten_free']

  const convertDashToUnderscore = (string: string): string => string.replace(/-/g, '_')

  return dietaryClaims
    .map((claim) => convertDashToUnderscore(claim.slug))
    .filter((claim) => streamlinedDietaryClaims.includes(claim))
}

export const RecipeCard = ({ calories, dietaryClaims, images, prepTimes, tag, title }: Props) => {
  const recipeImage = findImageUrls(images)
  const recipeImageUrl = getDefaultImage(recipeImage)
  const caloriesToDisplay = calories ?? undefined
  const cookingTime = prepTimes ?? undefined
  const dietaryLabels = getDietaryClaims(dietaryClaims)
  const getDietaryAcronym = (claim: string) =>
    dietaryAcronyms[claim as keyof typeof dietaryAcronyms]

  return (
    <RecipeTileContainer>
      <ImageContainer>
        {recipeImageUrl && <StyledImage src={recipeImageUrl} alt={title} />}
        <Box position={DisplayPosition.Absolute} top="1rem" left="1rem" display={Display.Flex}>
          {dietaryLabels.map((claim) => (
            <DietaryIcons
              key={claim}
              fontFamily={FontFamily.Bold}
              fontWeight={400}
              size={2}
              textAlign={TextAlign.Center}
            >
              {getDietaryAcronym(claim)}
            </DietaryIcons>
          ))}
        </Box>
      </ImageContainer>
      <Box position={DisplayPosition.Relative} flexGrow={1}>
        {tag && tag.length && (
          <LabelWrapperBox>
            <Box
              borderRadius={1.5}
              height="24px"
              display="flex"
              justifyContent={JustifyContent.Center}
              bg={Color.NeutralGrey_50}
              alignItems={AlignItems.Center}
            >
              <Space size={2} direction="horizontal" />
              <Body2>{tag}</Body2>
              <Space size={2} direction="horizontal" />
            </Box>
          </LabelWrapperBox>
        )}
        <Box
          display={Display.Flex}
          flexDirection={FlexDirection.Column}
          justifyContent={JustifyContent.SpaceBetween}
          height="100%"
          position={DisplayPosition.Relative}
        >
          <Box paddingH={4} paddingV={6}>
            <Heading6>{title}</Heading6>
            {cookingTime && (
              <Text size={2} color={Color.ColdGrey_600} display={Display.Inline}>
                {`${cookingTime} mins`}
              </Text>
            )}
            {caloriesToDisplay && (
              <Text size={2} color={Color.ColdGrey_600} display={Display.Inline}>
                &nbsp;&nbsp;|&nbsp;&nbsp;{`${caloriesToDisplay}kcal`}
              </Text>
            )}
          </Box>
        </Box>
      </Box>
    </RecipeTileContainer>
  )
}
