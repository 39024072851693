import React from 'react'

import {
  Heading4,
  Heading3,
  Box,
  Display,
  JustifyContent,
  Space,
} from '@gousto-internal/citrus-react'
import Immutable from 'immutable'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import { SocialButton } from 'components/SocialLinks/SocialButton'
import { SocialShareButtons } from 'components/SocialLinks/SocialShareButtons'
import { SocialShareSheetCTA } from 'components/SocialLinks/SocialShareSheetCTA'
import { UserRAFLink } from 'components/UserRAFLink'
import { getDiscountDurationFormatted } from 'selectors/rafOffer'

import { Offer } from './Offer'

import css from './ReferAFriend.css'

const propTypes = {
  rafOffer: ImmutablePropTypes.map({
    creditFormatted: PropTypes.string,
    firstBoxDiscountFormatted: PropTypes.string,
    firstMonthDiscountFormatted: PropTypes.string,
  }),
  referralCode: PropTypes.string.isRequired,
  userFirstName: PropTypes.string.isRequired,
  device: PropTypes.string.isRequired,
  trackingReferFriend: PropTypes.func.isRequired,
  trackingReferFriendSocialSharing: PropTypes.func.isRequired,
  trackUserFreeFoodLinkShare: PropTypes.func.isRequired,
  isSignUpFirstTwoBoxesExperimentEnabled: PropTypes.bool,
}

const defaultProps = {
  rafOffer: Immutable.Map(),
  isSignUpFirstTwoBoxesExperimentEnabled: false,
}

const ReferAFriend = ({
  rafOffer,
  referralCode,
  userFirstName,
  device,
  trackingReferFriend,
  trackingReferFriendSocialSharing,
  trackUserFreeFoodLinkShare,
  isSignUpFirstTwoBoxesExperimentEnabled,
}) => {
  const yourOffer = rafOffer.get('creditFormatted')
  const theirBoxOffer = rafOffer.get('firstBoxDiscountFormatted')
  const theirMonthOffer = rafOffer.get('firstMonthDiscountFormatted')
  const discountDuration = getDiscountDurationFormatted(rafOffer)

  return (
    <section data-testing="referAFriendSection">
      <Box paddingV={8} paddingH={4}>
        <Box display={['block', 'block', 'none', 'none']}>
          <Heading4 data-testing="referAFriendSectionTitle" size={5}>
            Invite your friends to try Gousto
          </Heading4>
        </Box>
        <Box display={['none', 'none', 'block', 'block']}>
          <Heading3 size={7}>Invite your friends to try Gousto</Heading3>
        </Box>
        <Space size={4} />
        <Box display={Display.Flex} justifyContent={JustifyContent.SpaceBetween}>
          <Offer isYourOffer offer={yourOffer} />
          <Offer
            isYourOffer={false}
            offer={theirBoxOffer}
            theirMonthOffer={theirMonthOffer}
            discountDuration={discountDuration}
          />
        </Box>
        <Space size={4} />
        {!isSignUpFirstTwoBoxesExperimentEnabled && (
          <Box display={['none', 'none', 'block', 'block']}>
            <UserRAFLink
              classLinkContainer={css.rafLink}
              referralCode={referralCode}
              trackingReferFriend={trackingReferFriend}
              trackUserFreeFoodLinkShare={trackUserFreeFoodLinkShare}
              isModal
            >
              <SocialButton text="Copy Invite Link" type="link" elementType="component" />
            </UserRAFLink>
            <p className={css.socialButtonsText}>Share your invite code:</p>
            <SocialShareButtons
              referralCode={referralCode}
              userFirstName={userFirstName}
              device={device}
              trackingReferFriendSocialSharing={trackingReferFriendSocialSharing}
              trackUserFreeFoodLinkShare={trackUserFreeFoodLinkShare}
              elementType="component"
            />
          </Box>
        )}
        {isSignUpFirstTwoBoxesExperimentEnabled ? (
          <SocialShareSheetCTA
            referralCode={referralCode}
            trackingReferFriend={trackingReferFriend}
            isFixed={false}
          />
        ) : (
          <Box
            data-testing="socialShareSheetCTAHidden"
            display={['block', 'block', 'none', 'none']}
          >
            <SocialShareSheetCTA
              referralCode={referralCode}
              trackingReferFriend={trackingReferFriend}
              isFixed={false}
            />
          </Box>
        )}
      </Box>
    </section>
  )
}

ReferAFriend.propTypes = propTypes

ReferAFriend.defaultProps = defaultProps

export { ReferAFriend }
