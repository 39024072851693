import React, { useEffect } from 'react'

import { useDispatch } from 'react-redux'

import redirectActions from 'actions/redirect'
import { client as clientRoutes } from 'config/routes'
import { useBasketImprovements } from 'hooks/useBasketImprovements'
import { useIsRightSizingExperimentEnabled } from 'hooks/useIsRightSizingExperimentEnabled'
import { useBasket } from 'routes/Menu/domains/basket'

import { Menu } from './Menu'
import { menuOverlayClick } from './actions/menuOverlayClick'
import { useAuth } from './domains/auth'

const MenuWrapper = (ownProps: any) => {
  const dispatch = useDispatch()
  const { addRecipe, removeRecipe, date } = useBasket()
  const { isAuthenticated } = useAuth()
  const {
    params: { orderId },
  } = ownProps
  const shouldRedirectToDeliveryDetails = isAuthenticated && !orderId && !date
  const isRightSizingExperimentEnabled = useIsRightSizingExperimentEnabled()
  const isBasketImprovementsEnabled = useBasketImprovements()

  const onOverlayClick = React.useCallback(() => {
    dispatch(menuOverlayClick(removeRecipe))
  }, [dispatch, removeRecipe])

  useEffect(() => {
    if (shouldRedirectToDeliveryDetails) {
      dispatch(redirectActions.replace(clientRoutes.deliveryDetails))
    }
  }, [dispatch, shouldRedirectToDeliveryDetails])

  return shouldRedirectToDeliveryDetails ? null : (
    <Menu
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...ownProps}
      addRecipeToBasket={addRecipe}
      onOverlayClick={onOverlayClick}
      isRightSizingExperimentEnabled={isRightSizingExperimentEnabled}
      isBasketImprovementsEnabled={isBasketImprovementsEnabled}
    />
  )
}

export { MenuWrapper }
