import React, { SyntheticEvent } from 'react'

import {
  AlignItems,
  Box,
  Color,
  Display,
  FlexDirection,
  Image as CitrusImage,
  JustifyContent,
  withCitrusTheme,
} from '@gousto-internal/citrus-react'
import { onSpaceOrEnter } from '../utils/accessibility'
import { Image } from '../types'
import { getDefaultImage, getSrcSet } from '../utils/formatters'

const CenteredImage = withCitrusTheme(CitrusImage, () => ({
  objectFit: 'cover',
  objectPosition: 'center',
}))

const ClickableBox = withCitrusTheme(Box, () => ({ cursor: 'pointer' }))

type RecipeProps = {
  image: Image
  onClick: (e: SyntheticEvent) => void
  name: string
}

export const Recipe = ({ image, onClick, name }: RecipeProps) => (
  <ClickableBox
    alignItems={AlignItems.Center}
    bg={Color.NeutralGrey_50}
    display={Display.Flex}
    flexDirection={FlexDirection.Column}
    height={['60px', '50px']}
    justifyContent={JustifyContent.SpaceBetween}
    role="button"
    tabIndex={0}
    width="100%"
    onClick={onClick}
    onKeyDown={onSpaceOrEnter(onClick)}
    minWidth={['50px', '62px']}
  >
    <CenteredImage
      alt={name}
      loading="lazy"
      src={getDefaultImage(image.crops)}
      srcSet={getSrcSet(image.crops)}
    />
  </ClickableBox>
)
