import React from 'react'

import { Color, Display, Box, BorderStyle, ResponsiveValue } from '@gousto-internal/citrus-react'

type InfoWrapperProps = {
  children: React.ReactNode
  background: ResponsiveValue<Color>
  borderColor: Color | undefined
  testId: string
}

export const InfoWrapper = ({ children, background, borderColor, testId }: InfoWrapperProps) => {
  return (
    <Box
      display={Display.Flex}
      borderColor={borderColor}
      borderWidth={0.5}
      borderStyle={BorderStyle.Solid}
      bg={background}
      paddingH={4}
      paddingV={4}
      borderRadius={1.5}
      gap={2}
      data-testid={testId}
    >
      {children}
    </Box>
  )
}
