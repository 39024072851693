import React from 'react'

import { useDecision } from '@optimizely/react-sdk'

import { useGetDiscountSofteningFreeDeliveryFromPromoCode } from 'hooks/useGetDiscountSofteningFreeDeliveryFromPromoCode'
import { useBasket } from 'routes/Menu/domains/basket'

import { Welcome } from './Welcome'
import { useWelcomeTracking } from './hooks/useWelcomeTracking'

const WelcomeWrapper = (ownProps: any) => {
  const { addRecipe } = useBasket()
  const { trackAppDownloadPrompt } = useWelcomeTracking()
  // BEET-224: OFX Discount Softening Free Delivery
  const discountSofteningFreeDeliveryValue = useGetDiscountSofteningFreeDeliveryFromPromoCode()
  // BEET-1-2: Sign up Box Experiment
  const [signUpFirstTwoBoxesExperiment] = useDecision('beetroot_1_2_box_experiment')
  const isSignUpFirstTwoBoxesExperimentEnabled = signUpFirstTwoBoxesExperiment.variationKey === 't1'

  const [downloadAppPromptExperiment] = useDecision('rockets_download_app_prompt')
  const isDownloadAppPromptExperimentEnabled = downloadAppPromptExperiment.variationKey === 't1'

  return (
    <Welcome
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...ownProps}
      addRecipeToBasket={addRecipe}
      discountSofteningFreeDeliveryValue={discountSofteningFreeDeliveryValue}
      isSignUpFirstTwoBoxesExperimentEnabled={isSignUpFirstTwoBoxesExperimentEnabled}
      isDownloadAppPromptExperimentEnabled={isDownloadAppPromptExperimentEnabled}
      trackAppDownloadPrompt={trackAppDownloadPrompt}
    />
  )
}

export { WelcomeWrapper }
