import Immutable from 'immutable'
import { RootStateOrAny } from 'react-redux'
import { Dispatch } from 'redux'

import { basketDateChange, basketSlotChange } from 'actions/basket'
import { collectionFilterIdReceive } from 'actions/filters'
import { redirect } from 'actions/redirect'
import { recommendationsSlug } from 'config/collections'
import { availablePortions } from 'config/portionSizes'
import { hasJustForYouCollection } from 'selectors/collections'
import { getCollectionIdWithName } from 'utils/collections'

import { getDefaultCollection } from '../selectors/collections'
import { GetState } from './types'

export const getPreselectedCollectionName = (
  state: RootStateOrAny,
  collectionNameFromQueryParam: string,
) => {
  if (hasJustForYouCollection(state) && !collectionNameFromQueryParam) {
    return recommendationsSlug
  }

  return collectionNameFromQueryParam
}

export const selectCollection =
  (collectionName: string) => (dispatch: Dispatch<any>, getState: GetState) => {
    const state = getState()
    let collectionId = getCollectionIdWithName(state, collectionName)

    if (!collectionId) {
      const defaultCollection = getDefaultCollection(state)

      if (defaultCollection) {
        collectionId = defaultCollection.get('id')
      }
    }

    dispatch(collectionFilterIdReceive(collectionId))
  }

export const setSlotFromIds =
  (slotId?: string | null, dayId?: string | null) =>
  (dispatch: Dispatch<any>, getState: GetState) => {
    const { boxSummaryDeliveryDays } = getState()
    if (dayId) {
      const day = boxSummaryDeliveryDays.find(
        (deliveryDay: Immutable.Map<string, any>) => deliveryDay.get('coreDayId') === dayId,
      )
      if (day) {
        const date = day.get('date')
        dispatch(basketDateChange(date))
        let slotIdToSet = ''
        if (slotId) {
          const matchingSlot = day
            .get('slots')
            .find((slot: Immutable.Map<string, any>) => slot.get('coreSlotId') === slotId)
          slotIdToSet = matchingSlot ? matchingSlot.get('id') : ''
        }
        dispatch(basketSlotChange(slotIdToSet))
      } else {
        dispatch(redirect('/menu'))
      }
    }
  }

export const parseNumPortions = (numPortions?: string | null) => {
  const parsedNumPortions = Number(numPortions)

  const isValidNumPortions =
    !Number.isNaN(parsedNumPortions) && availablePortions.includes(parsedNumPortions)

  return isValidNumPortions ? parsedNumPortions : undefined
}
