import React, { useEffect, useState } from 'react'

import { datadogLogs } from '@datadog/browser-logs'
import {
  AlignItems,
  Box,
  Display,
  Image,
  JustifyContent,
  RadioGroup,
  Space,
  Text,
} from '@gousto-internal/citrus-react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchIsPayPalEnabled } from '@library/api-payment'

import { setCurrentPaymentMethod } from 'routes/Checkout/checkoutActions'
import {
  getCurrentPaymentMethod,
  isPayPalReady as getIsPayPalReady,
} from 'routes/Checkout/checkoutPaymentSelectors'
import { usePricing } from 'routes/Menu/domains/pricing'
import { PaymentMethod } from 'routes/Signup/signupConfig'

import paypalPaymentMethod from '../../../assets/paypal.png'
import cardsPaymentMethod from './assets/payment_methods.png'

const paymentMethods = [PaymentMethod.Card, PaymentMethod.PayPal]
type PaymentMethods = typeof paymentMethods[number]

const getRadioLabels = (paymentMethod: PaymentMethods) => {
  if (paymentMethod === PaymentMethod.Card) {
    return (
      <Box
        display={Display.Flex}
        justifyContent={JustifyContent.SpaceBetween}
        alignItems={AlignItems.Center}
        paddingH={1}
        width="100%"
      >
        <Text>Card payment</Text>
        <Image
          src={cardsPaymentMethod}
          alt="logos of VISA, mastercard, maestro and AMEX"
          width="160px"
        />
      </Box>
    )
  }

  return (
    <>
      <Space direction="horizontal" size={1} />
      <Image src={paypalPaymentMethod} alt="paypal logo" width="100px" />
    </>
  )
}

export const CheckoutPaymentMethodSelector = () => {
  const [isPayPalEnabled, setIsPayPalEnabled] = useState<boolean>(true)
  const currentPaymentMethod = useSelector(getCurrentPaymentMethod)
  const isPayPalReady = useSelector(getIsPayPalReady)
  const dispatch = useDispatch()
  const handlePaymentMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setCurrentPaymentMethod(event.target.value))
  }

  const { pricing } = usePricing()

  const isPayPalDisabled = pricing?.total === '0.00' || !isPayPalEnabled

  const filteredPaymentMethods = isPayPalDisabled ? [PaymentMethod.Card] : paymentMethods

  const getRadioOptions = () =>
    filteredPaymentMethods.map((paymentMethod) => ({
      name: 'paymentMethod',
      value: paymentMethod,
      label: () => getRadioLabels(paymentMethod),
    }))

  useEffect(() => {
    const fetchPayPalStatus = async () => {
      try {
        const { IS_PAYPAL_ENABLED } = await fetchIsPayPalEnabled()
        setIsPayPalEnabled(IS_PAYPAL_ENABLED)
      } catch (error) {
        datadogLogs.logger.error(
          'Error signing up GooglePay user',
          undefined,
          error instanceof Error ? error : new Error(String(error)),
        )
        setIsPayPalEnabled(false)
      }
    }

    fetchPayPalStatus()
  }, [])

  return !isPayPalReady ? (
    <>
      <RadioGroup
        value={currentPaymentMethod}
        outline
        options={getRadioOptions()}
        onChange={handlePaymentMethodChange}
      />
    </>
  ) : null
}
