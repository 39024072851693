import {
  AlignItems,
  Box,
  Color,
  Display,
  FlexDirection,
  Icon,
  IconVariant,
  Text,
} from '@gousto-internal/citrus-react'
import React from 'react'

type InfoMessageProps = {
  type: 'warning' | 'success'
  messages: string[]
  iconVariant: IconVariant
}

export const InfoMessage = ({ type, messages, iconVariant }: InfoMessageProps) => {
  return (
    <Box
      display={Display.Flex}
      alignItems={type === 'success' ? AlignItems.Center : AlignItems.FlexStart}
      gap={2}
    >
      <Icon name={type} variant={iconVariant} />
      <Box
        display={Display.Flex}
        flexDirection={FlexDirection.Column}
        gap={1}
        color={Color.ColdGrey_900}
      >
        {messages.length > 1 ? (
          <>
            {messages.map((message, index) => (
              <Text key={index} display={Display.Flex}>
                <Icon name="bullet_point"> Bullet point </Icon>
                {message}
              </Text>
            ))}
          </>
        ) : (
          <Text> {messages[0]}</Text>
        )}
      </Box>
    </Box>
  )
}
