import { queueTrackingEvent } from '@library/tracking-integration'

type NextBoxActions =
  | 'click_next_box_plate'
  | 'click_choose_recipes'
  | 'click_recommended_recipe'
  | 'click_carousel_see_more'
  | 'carousel_scroll'
  | 'order_next_box_widget_loaded'

type OrderSummaryActions = 'click_order_summary'

type AppPromptActions =
  | 'app_download_screen_displayed'
  | 'app_download_cta_click'
  | 'app_download_cta_dismissed'

type ActionsNames = NextBoxActions | OrderSummaryActions | AppPromptActions

type Properties = Record<string, unknown>

type Common = {
  action: ActionsNames
  properties?: Properties
}

function trackEvent(action: ActionsNames, properties?: Properties) {
  const params = properties ? { action, properties } : { action }

  queueTrackingEvent<Common>(params)
}

export const useWelcomeTracking = () => ({
  trackSecondOrderIsLoaded: (hasSecondOrderLoaded: boolean) =>
    trackEvent('order_next_box_widget_loaded', { se_property: !hasSecondOrderLoaded ? 0 : 1 }),
  trackClickOnPlate: () => trackEvent('click_next_box_plate'),
  trackClickOnChooseRecipes: () => trackEvent('click_choose_recipes'),
  trackClickOnSeeMore: () => trackEvent('click_carousel_see_more'),
  trackScrollCarousel: () => trackEvent('carousel_scroll'),
  trackAppDownloadPrompt: () => trackEvent('app_download_screen_displayed'),
  trackAppDownloadCTAIsClicked: () => trackEvent('app_download_cta_click'),
  trackAppDownloadDismissCTAIsClicked: () => trackEvent('app_download_cta_dismissed'),
  trackClickOnRecipeInCarousel: (orderId: string) =>
    trackEvent('click_recommended_recipe', { order_id: orderId }),
  trackUnfoldSummary: ({ isOpen }: { isOpen: boolean }) =>
    trackEvent('click_order_summary', { close: !isOpen, open: isOpen }),
})
