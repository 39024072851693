import { actionTypes } from 'actions/actionTypes'
import { generateReference } from 'apis/customers'
import { CheckoutActionCreator } from 'routes/Checkout/models/ReduxTypes'

export const fetchGoustoRef: CheckoutActionCreator = () => async (dispatch, getState) => {
  let goustoRef = getState().checkout.get('goustoRef')
  if (!goustoRef) {
    const { data } = await generateReference()
    goustoRef = data.goustoRef

    dispatch({
      type: actionTypes.CHECKOUT_SET_GOUSTO_REF,
      goustoRef,
    })
  }
}
