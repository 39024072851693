import { useDecision } from '@optimizely/react-sdk'
import { useSelector } from 'react-redux'

import { getIsAuthenticated } from 'selectors/auth'

export function useBasketImprovements() {
  const [basketImprovements] = useDecision('turnips_ofx_basket_improvements')
  const isAuthenticated = useSelector(getIsAuthenticated)

  return basketImprovements.variationKey === 't1' && isAuthenticated
}
