import React, { useCallback } from 'react'

import { Box, DisplayPosition, withCitrusTheme, ZIndex } from '@gousto-internal/citrus-react'
import { useDispatch, useSelector } from 'react-redux'

import { Basket } from '@features/basket'
import { BasketRecipe } from '@features/basket/src/components/types'

import { actionTypes } from 'actions/actionTypes'
import * as trackingKeys from 'actions/trackingKeys'
import { NumPersonsToBoxDescriptors } from 'routes/BoxPrices/boxPricesModels'
import { getNumPersonsToBoxDescriptors } from 'routes/BoxPrices/boxPricesSelectors'
import { useGetYourBoxData } from 'routes/Checkout/Components/BoxDetailsHighlight/YourBox'
import { getBasketRecipesIDs } from 'routes/Checkout/Components/CheckoutBoxTangibility/selectors'
import { checkoutBasket } from 'routes/Menu/actions/menuCheckoutClick'
import { showDetailRecipe } from 'routes/Menu/actions/menuRecipeDetails'
import { useBasket } from 'routes/Menu/domains/basket'
import { usePricing } from 'routes/Menu/domains/pricing'
import { useGetUnavailableRecipeIds } from 'routes/Menu/hooks/useGetUnavailableRecipeIds'
import { getBrowserType } from 'selectors/browser'
import { createGetActionTypeIsPending } from 'selectors/status'

import { useCheckoutButton } from '../../hooks/useCheckoutButton'
import { Recipe } from './types'

const Container = withCitrusTheme(Box, () => ({
  margin: '0 auto',
}))

const BlurredBackground = withCitrusTheme(Box, () => ({
  background: 'rgba(255, 255, 255, 0.8)',
  backdropFilter: 'blur(30px)',
}))

export const BasketContainer = () => {
  const isLoading = useSelector(createGetActionTypeIsPending(actionTypes.MENU_FETCH_DATA))
  const { isCheckoutButtonDisabled, isCheckoutButtonPending } = useCheckoutButton()
  const { maxRecipesNum, numPortions, menuRecipesStore } = useGetYourBoxData()
  const basketRecipesIds = useSelector(getBasketRecipesIDs)
  const unavailableRecipeIds = useGetUnavailableRecipeIds()
  const { removeRecipe, recipeCount, getQuantitiesForRecipeId } = useBasket()

  const selectedRecipes = basketRecipesIds.reduce<Array<BasketRecipe>>((acc, recipeId: number) => {
    const recipeIdString = recipeId.toString()
    const recipe = menuRecipesStore.get(recipeIdString)?.toJS() as Recipe

    if (unavailableRecipeIds.includes(recipeIdString) && !recipe) {
      removeRecipe(recipeIdString)
    } else if (recipe) {
      const defaultImageCrops = recipe?.media?.images?.[0].urls || []
      const moodImageCrops = recipe?.media?.images.find(
        (image) => image.type === 'mood-image',
      )?.urls

      const quantity = getQuantitiesForRecipeId(recipeIdString)

      const basketRecipe = {
        moodImage: { crops: moodImageCrops || defaultImageCrops },
        name: recipe?.title,
        uuid: recipe?.uuid,
      }

      if (quantity > 1) {
        for (let i = 0; i < quantity; i++) {
          acc.push(basketRecipe)
        }
      } else {
        acc.push(basketRecipe)
      }
    }

    return acc
  }, [])

  const boxPrices = useSelector(getNumPersonsToBoxDescriptors) as NumPersonsToBoxDescriptors
  const boxPricesForNumPortions = boxPrices?.[numPortions] || []
  const pricesPerServing = [
    '', // Placeholder for the first recipe
    ...boxPricesForNumPortions.map((prices) => prices.price_per_portion),
  ]

  const dispatch = useDispatch()
  const { pricing } = usePricing()
  const view = useSelector(getBrowserType)
  const section = trackingKeys.menu

  const handleCheckout = useCallback(
    (e) => {
      e.stopPropagation()
      if (!isCheckoutButtonPending) {
        dispatch(checkoutBasket({ section, view, pricing, removeRecipe }))
      }
    },
    [dispatch, section, view, pricing, removeRecipe, isCheckoutButtonPending],
  )

  const handleRecipeClick = useCallback(
    (recipeUuid) => dispatch(showDetailRecipe(recipeUuid)),
    [dispatch],
  )

  // If there are no recipes in the basket, don't show the basket
  if (recipeCount <= 0 || isLoading) {
    return null
  }

  return (
    <BlurredBackground
      bottom="0"
      left="0"
      position={DisplayPosition.Fixed}
      width="100%"
      zIndex={ZIndex.Sticky}
    >
      <Container maxWidth="1600px">
        <Basket
          isCheckoutDisabled={isCheckoutButtonDisabled}
          isLoading={isLoading}
          maxNumberRecipes={maxRecipesNum}
          onCheckout={handleCheckout}
          onRecipeClick={(recipeUuid) => handleRecipeClick(recipeUuid)}
          pricesPerServing={pricesPerServing}
          selectedRecipes={selectedRecipes}
          totalPrice={pricing?.total}
        />
      </Container>
    </BlurredBackground>
  )
}
