import { connect } from 'react-redux'

import {
  onKeep,
  onConfirm,
  getRecoveryContent,
  modalVisibilityChange,
  trackViewDiscountReminder
} from 'actions/onScreenRecovery'
import { actionTypes } from 'actions/actionTypes'
import { OnScreenRecoveryView } from './OnScreenRecoveryView'

const mapStateToProps = (state) => ({
  triggered: state.onScreenRecovery.get('triggered'),
  title: state.onScreenRecovery.get('title'),
  offers: state.onScreenRecovery.get('offers'),
  promoCode: state.onScreenRecovery.get('promoCode'),
  offer: state.onScreenRecovery.get('offer'),
  modalType: state.onScreenRecovery.get('modalType'),
  isFetching: state.pending.get(actionTypes.ORDER_SKIP_RECOVERY_TRIGGERED),
  pauseReason: state.onScreenRecovery.get('pauseReason'),
})

const mapDispatchToProps = {
  onKeep,
  onConfirm,
  modalVisibilityChange,
  getRecoveryContent,
  trackViewDiscountReminder
}

export const OnScreenRecoveryViewContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OnScreenRecoveryView)
