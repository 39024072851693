/**
 * This code was migrated from webclient. For the original file's history, see
 * https://github.com/Gousto/gousto-webclient/blob/1544f0f8/src/apps/webclient/src/config/service-environment/service-manifest.ts
 */

/*
 * @type {ServiceManifest} - type for describing the service manifest
 */
import type { ServiceManifest } from './types'

export const serviceManifest: ServiceManifest = {
  auth: [
    {
      majorVersion: 1,
      version: 'v1.0.0',
      basePath: '/auth/v1.0.0',
    },
    {
      majorVersion: 2,
      version: 'v2',
      basePath: '/auth/v2',
    },
  ],
  brand: [
    {
      majorVersion: 1,
      version: 'v1',
      basePath: '/brand/v1',
    },
  ],
  clientmetrics: [
    {
      majorVersion: 1,
      version: 'v1',
      basePath: '/clientmetrics/v1',
    },
  ],
  complaints: [
    {
      majorVersion: 1,
      version: 'v1',
      basePath: '/complaints/v1',
    },
  ],
  cookbook: [
    {
      majorVersion: 1,
      version: 'v1',
      basePath: '/cookbook/v1',
    },
  ],
  core: [
    {
      majorVersion: 1,
      version: '',
      basePath: '',
    },
  ],
  customers: [
    {
      majorVersion: 1,
      version: 'v1',
      basePath: '/customers/v1',
    },
    {
      majorVersion: 2,
      version: 'v2',
      basePath: '/customers/v2',
    },
    {
      majorVersion: 3,
      version: 'v3',
      basePath: '/customers/v3',
    },
  ],
  deliveries: [
    {
      majorVersion: 1,
      version: 'v1.0',
      basePath: '/deliveries/v1.0',
    },
    {
      majorVersion: 2,
      version: 'v2',
      basePath: '/deliveries/v2',
    },
  ],
  loggingmanager: [
    {
      majorVersion: 1,
      version: '',
      basePath: '/loggingmanager',
    },
  ],
  menu: [
    {
      majorVersion: 1,
      version: 'v1',
      basePath: '/menu/v1',
    },
    {
      majorVersion: 2,
      version: 'v2',
      basePath: '/menu/v2',
    },
    {
      majorVersion: 3,
      version: 'v3',
      basePath: '/menu/v3',
    },
  ],
  order: [
    {
      majorVersion: 1,
      version: 'v1',
      basePath: '/order/v1',
    },
    {
      majorVersion: 2,
      version: 'v2',
      basePath: '/order/v2',
    },
  ],
  orders: [
    {
      majorVersion: 1,
      version: 'v1',
      basePath: '/orders/v1',
    },
    {
      majorVersion: 2,
      version: 'v2',
      basePath: '/orders/v2',
    },
  ],
  orderskiprecovery: [
    {
      majorVersion: 1,
      version: 'v1',
      basePath: '/orderskiprecovery/v1',
    },
  ],
  payments: [
    {
      majorVersion: 1,
      version: 'v1',
      basePath: '/payments/v1',
    },
  ],
  paymentmethodquery: [
    {
      majorVersion: 1,
      version: 'v1',
      basePath: '/paymentmethodquery/v1',
    },
  ],
  products: [
    {
      majorVersion: 1,
      version: 'v1.0',
      basePath: '/products/v1.0',
    },
    {
      majorVersion: 2,
      version: 'v2.0',
      basePath: '/products/v2.0',
    },
  ],
  ssr: [
    {
      majorVersion: 1,
      version: 'v1',
      basePath: '/ssr/v1',
    },
    {
      majorVersion: 2,
      version: 'v2',
      basePath: '/ssr/v2',
    },
    {
      majorVersion: 3,
      version: 'v3',
      basePath: '/ssr/v3',
    },
  ],
  ssrrecipecards: [
    {
      majorVersion: 1,
      version: 'v1',
      basePath: '/ssrrecipecards/v1',
    },
  ],
  subpauseosr: [
    {
      majorVersion: 2,
      version: 'v2',
      basePath: '/subpauseosr/v2',
    },
  ],
  subscriptioncommand: [
    {
      majorVersion: 1,
      version: 'v1',
      basePath: '/subscriptioncommand/v1',
    },
  ],
  subscriptionquery: [
    {
      majorVersion: 1,
      version: 'v1',
      basePath: '/subscriptionquery/v1',
    },
  ],
  tastepreferences: [
    {
      majorVersion: 1,
      version: 'v1',
      basePath: '/tastepreferences/v1',
    },
  ],
  userbucketing: [
    {
      majorVersion: 1,
      version: 'v1',
      basePath: '/userbucketing/v1',
    },
  ],
  userfeedback: [
    {
      majorVersion: 1,
      version: 'v1',
      basePath: '/userfeedback/v1',
    },
    {
      majorVersion: 2,
      version: 'v2',
      basePath: '/userfeedback/v2',
    },
  ],
  webclient: [
    {
      majorVersion: 1,
      version: '',
      basePath: '',
    },
  ],
  workable: [
    {
      majorVersion: 1,
      version: 'v1',
      basePath: '/workable/v1',
    },
  ],
}

export const getServiceManifest = () => serviceManifest
