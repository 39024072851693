export type PortionSizes = 1 | 2 | 3 | 4 | 5

export type PortionSizeDescriptor = {
  heading: string
  recipeInfoStrongText: string
  suitableFor: string[]
  value: number
  originalPrice?: string
  discountedPrice?: string
}

export type PortionSizesConfig = {
  [key in PortionSizes]: PortionSizeDescriptor
}
export const availablePortions = [1, 2, 3, 4, 5]

export const portionSizesConfig: PortionSizesConfig = {
  1: {
    heading: '1 person box',
    recipeInfoStrongText: '2 to 5',
    suitableFor: ['1 adult'],
    value: 1,
  },
  2: {
    heading: '2 person box',
    recipeInfoStrongText: '2 to 5',
    suitableFor: ['2 adults (or 1 + leftovers)', '1 adult and 1-2 children'],
    value: 2,
  },
  3: {
    heading: '3 person box',
    recipeInfoStrongText: '2 to 5',
    suitableFor: ['3 adults (or 2 + leftovers)', '2 adults and 1-2 children'],
    value: 3,
  },
  4: {
    heading: '4 person box',
    recipeInfoStrongText: '2 to 5',
    suitableFor: ['4 adults (or 2-3 + leftovers)', '2 adults and 2-3 children'],
    value: 4,
  },
  5: {
    heading: '5 person box',
    recipeInfoStrongText: '2 or 3',
    suitableFor: ['5 adults (or 3-4 + leftovers)', '2-3 adults and 2-4 children'],
    value: 5,
  },
}

export const pricingTransparencyPortionSizesConfig: PortionSizesConfig = {
  1: {
    heading: '1 person box',
    recipeInfoStrongText: '2 to 5',
    suitableFor: ['1 adult'],
    value: 1,
    originalPrice: '19.49',
    discountedPrice: '9.75',
  },
  2: {
    heading: '2 person box',
    recipeInfoStrongText: '2 to 5',
    suitableFor: ['2 adults (or 1 + leftovers)', '1 adult and 1-2 children'],
    value: 2,
    originalPrice: '26.75',
    discountedPrice: '13.38',
  },
  3: {
    heading: '3 person box',
    recipeInfoStrongText: '2 to 5',
    suitableFor: ['3 adults (or 2 + leftovers)', '2 adults and 1-2 children'],
    value: 3,
    originalPrice: '31.99',
    discountedPrice: '16.00',
  },
  4: {
    heading: '4 person box',
    recipeInfoStrongText: '2 to 5',
    suitableFor: ['4 adults (or 2-3 + leftovers)', '2 adults and 2-3 children'],
    value: 4,
    originalPrice: '33.75',
    discountedPrice: '16.88',
  },
  5: {
    heading: '5 person box',
    recipeInfoStrongText: '2 or 3',
    suitableFor: ['5 adults (or 3-4 + leftovers)', '2-3 adults and 2-4 children'],
    value: 5,
    originalPrice: '44.25',
    discountedPrice: '22.13',
  },
}
