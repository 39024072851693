import React, { SyntheticEvent } from 'react'
import { BasketRecipe } from '../types'

import {
  AlignItems,
  Box,
  Display,
  FlexDirection,
  JustifyContent,
} from '@gousto-internal/citrus-react'
import { Recipe } from '../Recipe/Recipe'
import { RecipePlaceholder } from '../RecipePlaceholder/RecipePlaceholder'
import { CheckoutButton } from '../CheckoutButton/CheckoutButton'

type BasketProps = {
  isCheckoutDisabled?: boolean
  isLoading?: boolean
  maxNumberRecipes: number
  onCheckout: (e: SyntheticEvent) => void
  onRecipeClick: (recipeUuid: string) => void
  pricesPerServing: string[]
  selectedRecipes: BasketRecipe[]
  totalPrice?: string | null
}

export const Basket = ({
  isCheckoutDisabled = true,
  isLoading = true,
  maxNumberRecipes,
  onCheckout,
  onRecipeClick,
  pricesPerServing,
  selectedRecipes,
  totalPrice,
}: BasketProps) => {
  const handleOnRecipeClick = (e: SyntheticEvent, recipeUuid: string) => {
    e.stopPropagation()
    onRecipeClick(recipeUuid)
  }

  return (
    <Box
      display={Display.Flex}
      justifyContent={JustifyContent.FlexEnd}
      paddingH={[3, 3, 8]}
      width="100%"
    >
      <Box
        alignItems={AlignItems.Center}
        display={Display.Flex}
        flexDirection={[
          FlexDirection.Column,
          FlexDirection.Column,
          FlexDirection.Row,
          FlexDirection.Row,
        ]}
        gap={2}
        paddingV={2}
        width={['100%', '100%', '85%', '50%']}
      >
        <Box
          display={Display.Flex}
          flexGrow={1}
          gap={2}
          justifyContent={JustifyContent.SpaceEvenly}
          width="100%"
        >
          {Array.from({ length: Math.max(maxNumberRecipes, selectedRecipes.length) }).map(
            (_, index) => {
              const recipe = selectedRecipes[index]
              return (
                <Box key={index} flexGrow={1} flexShrink={1} flexBasis="0">
                  {recipe && !isLoading ? (
                    <Recipe
                      image={recipe.moodImage}
                      onClick={(e) => handleOnRecipeClick(e, recipe.uuid)}
                      name={recipe.name}
                    />
                  ) : (
                    <RecipePlaceholder
                      basketPosition={index + 1}
                      pricePerServing={pricesPerServing[index]}
                    />
                  )}
                </Box>
              )
            },
          )}
        </Box>
        <Box width={['100%', '100%', '185px', '185px']}>
          <CheckoutButton
            disabled={isCheckoutDisabled}
            onClick={onCheckout}
            totalPrice={totalPrice}
          />
        </Box>
      </Box>
    </Box>
  )
}
