import React from 'react'

import {
  Body1,
  ButtonColorVariant,
  Heading5,
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
  useModal,
} from '@gousto-internal/citrus-react'
import { useDispatch, useSelector } from 'react-redux'
import { browserHistory } from 'react-router'

import { basketDateChange, basketPreviewOrderChange, basketSlotClear } from 'actions/basket'
import configRoutes from 'config/routes'
import { checkoutClearErrors } from 'routes/Checkout/checkoutActions'
import { getCheckoutErrorByKey } from 'routes/Checkout/checkoutSelectors'

export const ASSIGN_ORDER_FAILED = 'assign-order-failed'
export const orderNotAssignableErrorModal = 'orderNotAssignableErrorModal'

export const modalCopy: Record<string, string> = {
  heading: 'Your selected delivery date is no longer available',
  bodyLine1: 'We have a 12pm cut-off so we can pack your box on time.',
  bodyLine2: 'Choose another date that suits you to complete your order.',
  buttonCopy: 'Change delivery date',
}

export const OrderNotAssignableErrorModal = () => {
  const dispatch = useDispatch()
  const { closeCurrentModal } = useModal()
  const orderSaveError = useSelector(getCheckoutErrorByKey('CHECKOUT_SIGNUP'))
  const isOrderNotAssignable = orderSaveError === ASSIGN_ORDER_FAILED

  const resetBasketAndClearErrors = () => {
    dispatch(basketDateChange(''))
    dispatch(basketSlotClear())
    dispatch(basketPreviewOrderChange('', ''))
    dispatch(checkoutClearErrors())
  }

  const handleModalClose = () => {
    closeCurrentModal()
    resetBasketAndClearErrors()
    browserHistory.push(configRoutes.client.menu)
  }

  return (
    <Modal
      isOpen={isOrderNotAssignable}
      name={orderNotAssignableErrorModal}
      onRequestClose={handleModalClose}
    >
      <ModalHeader>
        <Heading5>{modalCopy.heading}</Heading5>
      </ModalHeader>
      <ModalBody>
        <Body1>{modalCopy.bodyLine1}</Body1>
        <Body1>{modalCopy.bodyLine2}</Body1>
      </ModalBody>
      <ModalFooter>
        <ModalButton colorVariant={ButtonColorVariant.Primary} onClick={handleModalClose}>
          {modalCopy.buttonCopy}
        </ModalButton>
      </ModalFooter>
    </Modal>
  )
}
