import React from 'react'

import {
  AlignItems,
  Box,
  Button,
  Display,
  FlexDirection,
  Heading3,
  Modal,
  ModalBody,
  ModalClose,
  ModalHeader,
  Text,
  useModal,
} from '@gousto-internal/citrus-react'
import { browserHistory } from 'react-router'

import configRoutes from 'config/routes'
import { useCheckoutError } from 'routes/Menu/hooks/useCheckoutError'

export const CheckoutErrorModal = () => {
  const { closeCurrentModal } = useModal()
  const { errorMessage, showError } = useCheckoutError()

  const message =
    errorMessage ||
    'There was an issue when confirming your order. Head to your account to check your upcoming deliveries.'

  return (
    <Modal isOpen={showError} name="checkout-error-modal">
      <ModalHeader>
        <Heading3>Uh oh, something went wrong</Heading3>
        <ModalClose onClose={closeCurrentModal} />
      </ModalHeader>
      <ModalBody>
        <Box
          alignItems={AlignItems.Center}
          display={Display.Flex}
          flexDirection={FlexDirection.Column}
          gap={4}
        >
          <Text>{message}</Text>
          <Button onClick={() => browserHistory.push(configRoutes.client.myDeliveries)}>
            View deliveries
          </Button>
        </Box>
      </ModalBody>
    </Modal>
  )
}
