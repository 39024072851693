import { useContext } from 'react'

import { OptimizelyContext } from '@optimizely/react-sdk'

import { useAuth } from 'routes/Menu/domains/auth'
import Cookies from 'utils/GoustoCookies'
import logger from 'utils/logger'

export function useIsUrgentCheckoutTimerEnabled(): boolean {
  const { isAuthenticated } = useAuth()
  const { optimizely } = useContext(OptimizelyContext)
  let hasAcceptedAnalytics = false
  try {
    const consentCookieString = Cookies.get('v1_gousto_cookie_consent')
    const consentCookie = consentCookieString ? JSON.parse(consentCookieString) : {}
    hasAcceptedAnalytics = consentCookie?.categories?.includes('ANALYTICS')
  } catch (error) {
    if (error instanceof Error) {
      ;(logger as any).error(error.message)
    }
  }

  // [BEET-465] We only allocate users to the experiment if they have consented
  // to the Analytics cookies and are not authenticated
  const { enabled, variationKey } =
    optimizely && hasAcceptedAnalytics && !isAuthenticated
      ? optimizely.decide('beetroots_ofx_urgent_checkout_timer')
      : { enabled: false, variationKey: undefined }

  const isEnabled = enabled && variationKey === 't1'

  return isEnabled
}
