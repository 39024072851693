import React, { useCallback } from 'react'

import {
  Box,
  Button,
  ButtonColorVariant,
  ButtonSize,
  ButtonVariant,
  Display,
  DisplayPosition,
  FlexDirection,
  JustifyContent,
  Overflow,
  Icon,
  Link,
  AlignItems,
  IconVariant,
  Spinner,
  Color,
} from '@gousto-internal/citrus-react'
import { browserCookies } from '@gousto-internal/storage-consent-web-package'
import { browserHistory } from 'react-router'

import { useWelcomeTracking } from 'routes/Welcome/hooks/useWelcomeTracking'

import { useRecommendedRecipes } from '../useRecommendedRecipes'
import { RecipeCard } from './RecipeCard'
import { useCarouselNav } from './useCarouselNav'

type Order = {
  id: string
  deliveryDate: string
  box: {
    numPortions: string
  }
  prices: {
    isDeliveryFree: boolean
    percentageOff: string
  }
}

type Props = {
  order: Order
}

const EMPTY_UUID = '00000000-0000-0000-0000-000000000000'

export const Carousel = ({ order }: Props) => {
  const { trackClickOnRecipeInCarousel, trackClickOnSeeMore } = useWelcomeTracking()
  const { emblaRef, prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } =
    useCarouselNav()

  const orderMenuPath = `/menu/${order?.id}`

  const handleCardClick = useCallback(() => {
    trackClickOnRecipeInCarousel(order.id)

    browserHistory.push(orderMenuPath)
  }, [order?.id, trackClickOnRecipeInCarousel, orderMenuPath])

  const handleSeeMoreLink = useCallback(() => {
    trackClickOnSeeMore()

    browserHistory.push(orderMenuPath)
  }, [trackClickOnSeeMore, orderMenuPath])

  const [consented, sessionId] = browserCookies.getString('gousto_session_id')
  const { recipes } = useRecommendedRecipes({
    deliveryDate: order?.deliveryDate,
    numPortions: parseInt(order?.box?.numPortions, 10),
    sessionId: consented ? sessionId || '' : EMPTY_UUID,
  })

  const isNextButnHidden = nextBtnDisabled ? 'none' : 'flex'
  const isPrevButnHidden = prevBtnDisabled ? 'none' : 'flex'
  const renderSeeMoreLink = () => (
    <Box
      className="embla__slide"
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      gap={2}
      alignItems={AlignItems.Center}
      justifyContent={JustifyContent.Center}
      onClick={handleSeeMoreLink}
      style={{ minWidth: '135px' }}
    >
      <Icon name="arrow_right" variant={IconVariant.Actionable} style={{ cursor: 'pointer' }} />
      <Link href={orderMenuPath}>See more</Link>
    </Box>
  )

  return (
    <Box
      data-testid="carousel"
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      position={DisplayPosition.Relative}
    >
      <Box className="embla__viewport" ref={emblaRef} width="100%" overflowX={Overflow.Hidden}>
        <Box className="embla__container" display={Display.Flex} width="100%" gap={2}>
          {recipes?.map((card, index) => (
            <Box
              // eslint-disable-next-line react/no-array-index-key
              key={`${card.title}-${index}`}
              className="embla__slide"
              flexGrow={0}
              flexShrink={0}
              width={['228px', '350px']}
              height="310px"
              role="button"
              onClick={handleCardClick}
            >
              <RecipeCard
                tag={card.tag}
                images={card.images}
                dietaryClaims={card.dietaryClaims}
                title={card.title}
                calories={card.calories}
                prepTimes={card.prepTimes}
              />
            </Box>
          ))}
          {recipes.length === 0 ? (
            <Box
              paddingH={4}
              paddingV={4}
              width="100%"
              display={Display.Flex}
              alignItems={AlignItems.Center}
              justifyContent={JustifyContent.Center}
            >
              <Spinner color={Color.Secondary_400} size={6} />
            </Box>
          ) : (
            renderSeeMoreLink()
          )}
        </Box>
        <Button
          aria-label="Previous slide"
          disabled={prevBtnDisabled}
          className="embla__prev"
          colorVariant={ButtonColorVariant.Secondary}
          icon="arrow_back"
          onClick={onPrevButtonClick}
          size={ButtonSize.Large}
          style={{
            borderRadius: '50%',
            display: isPrevButnHidden,
            position: 'absolute',
            bottom: '46%',
            left: '3%',
          }}
          variant={ButtonVariant.Icon}
        />
        <Button
          aria-label="Next slide"
          disabled={nextBtnDisabled}
          className="embla__next"
          colorVariant={ButtonColorVariant.Secondary}
          icon="arrow_right"
          onClick={onNextButtonClick}
          size={ButtonSize.Large}
          style={{
            borderRadius: '50%',
            display: isNextButnHidden,
            position: 'absolute',
            bottom: '46%',
            right: '3%',
          }}
          variant={ButtonVariant.Icon}
        />
      </Box>
    </Box>
  )
}
